import React from 'react'
import TextField from "@material-ui/core/TextField";
import {Chip, FormLabel} from "@material-ui/core";
import globalTheme from "../theme/globalTheme";

/**
 * Final FormプラグインとMaterial UI Formフィールドを結合するコンポーネント
 * final formのテキストエリア
 *
 * @param {string} name - テキストフィールドの名前
 * @param {*} onChange - テキスト変更イベント
 * @param {string} value - テキスト値
 * @param {number} multiline - テキストエリアの行数
 * @param {*} restInput - 他の属性
 * @param {Object} meta - final formメタ情報
 * @param {null|boolean} loadingOnDisable - ロード中の状態
 * @param {*} rest - 他の属性
 * @returns {JSX.Element}
 */
const FinalFormTextArea = ({
                               input: {name, onChange, value, multiline = 0, ...restInput},
                               meta,
                               loadingOnDisable,
                               ...rest
                           }) => {
    const classes = globalTheme.defaultFormStyle();
    const loadingClass = loadingOnDisable && rest.disabled ? classes.loadingOnDisable : undefined
    return (
        <div>
            <FormLabel className={loadingClass}>
                {/* この項目は必須ですか？ チェック */}
                {rest.label}
                {rest.required &&
                <Chip label={"必須"} size="small" className={[classes.chip, loadingClass].join(" ") }/>
                }
            </FormLabel>
            <TextField
                className={[classes.textfield, loadingClass].join(" ")}
                {...rest}
                name={name}
                data-testid={name}
                multiline={true}
                rows={5}
                variant="outlined"
                helperText={meta.touched ? meta.error : undefined}
                error={meta.error && meta.touched}
                inputProps={restInput}
                onChange={onChange}
                value={value}
                label=""
            />
        </div>
    )
};
export default FinalFormTextArea;
