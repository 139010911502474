import {call, put} from 'redux-saga/effects';
import {
    managerAccountEditLoad,
    managerDeleteAccountFailure, managerDeleteAccountLoading,
    managerDeleteAccountSuccess,
    managerEditAccountFailure,
    managerEditAccountSuccess,
    managerFailureAccountList,
    managerLoadAccountList,
    managerLoadedAccountList,
    managerLoadingAccountList,
    managerLoadingSalesForceAccount,
    managerLoadingSalesForceUser,
    managerRegisterNewAccountFailure,
    managerRegisterNewAccountLoading,
    managerRegisterNewAccountSuccess,
    managerSearchSalesForceAccount,
    managerSearchSalesForceUser,
    managerSelectAccountUserFailure,
    managerSelectAccountUserLoad,
    managerSelectAccountUserSuccess,
    managerSelectedSalesForceAccount,
    managerSelectedSalesForceAccountFailure,
    managerSelectedSalesForceAccountSuccess,
    managerSelectedSalesForceUserFailure,
    managerSelectedSalesForceUserSuccess,
    managerViewRegisterToggle
} from "./actions";
import {
    managerAddAccounts,
    managerCallAccounts,
    managerCallAccountUsers,
    managerDeleteAccounts,
    managerUpdateAccount,
    searchSalesForceAccount,
    searchSalesForceUser
} from "./api";
import {error_raise} from "../../root/actions";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * アカウント取得リクエスト
 * @param data
 */
export function* managerSelectAccountList(data) {
    yield put(managerLoadingAccountList())
    try {
        const accounts = yield call(managerCallAccounts, data.payload.access_token, null);
        yield put(managerLoadedAccountList(accounts.data));
    } catch (e) {
        yield put(managerFailureAccountList(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 新規アカウントリクエスト
 * @param accountData
 */
export function* managerAddAccountList(accountData) {
    yield put(managerRegisterNewAccountLoading())
    try {
        yield call(managerAddAccounts, accountData.payload.data, accountData.payload.access_token)
        yield put(managerRegisterNewAccountSuccess())
        yield put(managerLoadAccountList(accountData.payload.access_token))
    } catch (e) {
        yield put(managerRegisterNewAccountFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * アカウント検索リクエスト
 * @param data
 */
export function* managerFilterAccountList(data) {
    yield put(managerLoadingAccountList())
    try {
        const searchResults = yield call(managerCallAccounts, data.payload.access_token, data.payload.data)
        yield put(managerLoadedAccountList(searchResults.data))
    } catch (e) {
        yield put(managerFailureAccountList(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * アカウント削除リクエスト
 * @param accountData
 */
export function* managerDeleteAccount(accountData) {
    yield put(managerDeleteAccountLoading())
    try {
        yield call(managerDeleteAccounts, accountData.payload.account.uuid, accountData.payload.access_token)
        yield put(managerDeleteAccountSuccess())
        yield put(managerLoadAccountList(accountData.payload.access_token))
    } catch (e) {
        yield put(managerDeleteAccountFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * アカウント編集リクエスト
 * @param accountData
 */
export function* managerEditAction(accountData) {
    yield put(managerAccountEditLoad())
    try {
        yield call(managerUpdateAccount, accountData.payload.account.uuid, accountData.payload.updateInformation, accountData.payload.access_token)
        yield put(managerEditAccountSuccess());
        yield put(managerLoadAccountList(accountData.payload.access_token))
    } catch (e) {
        yield put(managerEditAccountFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * SFアカウント検索リクエスト（最初のみ）
 * @param search
 */
export function* initSFAccount(search) {
    yield put(managerViewRegisterToggle());
    yield put(managerSearchSalesForceAccount("", search.payload.access_token))
}

/**
 * SFアカウント検索リクエスト
 * @param search
 */
export function* searchSfAccount(search) {
    yield put(managerLoadingSalesForceAccount())
    try {
        let accounts = yield call(searchSalesForceAccount, search.payload.account_name, search.payload.access_token)
        yield put(managerSelectedSalesForceAccountSuccess(accounts.data[0].accounts))
    } catch (e) {
        yield put(managerSelectedSalesForceAccountFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * SFアカウントでSFユーザー検索リクエスト
 * @param search
 */
export function* selectedSfAccountUserSearch(search) {
    try {
        yield put(managerSelectedSalesForceAccount(search.payload.salesForceAccount));
        yield put(managerSearchSalesForceUser("", search.payload.access_token, search.payload.salesForceAccount))
    } catch (e) {
        yield put(managerSelectedSalesForceUserFailure(e))
    }
}

/**
 * SFユーザー検索
 * @param search
 */
export function* searchSfUser(search) {
    yield put(managerLoadingSalesForceUser())
    try {
        let users = yield call(searchSalesForceUser, search.payload.user_name, search.payload.access_token, search.payload.account)
        yield put(managerSelectedSalesForceUserSuccess(users.data[0].users))
    } catch (e) {
        yield put(managerSelectedSalesForceUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 選択したアカウントでユーザー取得リクエスト
 * @param data
 */
export function* searchAccountUsers(data) {
    yield put(managerSelectAccountUserLoad())
    try {
        // 成功
        let users = yield call(managerCallAccountUsers, data.payload.access_token, data.payload.accountId);
        yield put(managerSelectAccountUserSuccess(users.data))
    } catch (e) {
        // 失敗
        yield put(managerSelectAccountUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
