import React from 'react'
import TextField from "@material-ui/core/TextField";
import {Chip, FormLabel} from "@material-ui/core";
import globalTheme from '../theme/globalTheme'

/**
 * Final FormプラグインとMaterial UI Formフィールドを結合するコンポーネント
 * final formの入力フェルド
 *
 * @param {string} name - テキストフィールドの名前
 * @param {*} onChange - テキスト変更イベント
 * @param {string} value - テキスト値
 * @param {number} multiline - テキストエリアの行数
 * @param {*} restInput - 他の属性
 * @param {Object} meta - final formメタ情報
 * @param loadingOnDisable - ロード中の状態
 * @param {*} rest - 他の属性
 * @returns {JSX.Element}
 */
const FormForPostalCode = ({
                                input: {name, onChange, value, multiline = 0, ...restInput},
                                meta,
                                loadingOnDisable,
                                ...rest
                            }) => {
    const classes = globalTheme.defaultFormStyle();
    const loadOnDisable = loadingOnDisable && rest.disabled ? classes.loadingOnDisable : undefined
    return (
        <div>
            <FormLabel className={loadOnDisable}>
                {rest.label}
                {rest.required &&
                <Chip label={"必須"} size="small" className={[classes.chip, loadOnDisable].join(" ") }/>
                }
            </FormLabel>
            <TextField
                className={[classes.textfield, loadOnDisable].join(" ")}
                {...rest}
                name={name}
                data-testid={name}
                variant="outlined"
                multiline={(multiline > 0)}
                onKeyPress={e => {e.key === "Enter" && e.preventDefault()}}
                disabled={rest.disabled}
                rows={multiline}
                error={meta.error && meta.touched}
                inputProps={restInput}
                onChange={onChange}
                value={value}
                label=""
            />
        </div>)
}
export default FormForPostalCode;
