import React from 'react';
import {withTranslation} from "react-i18next";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import globalTheme from "../../theme/globalTheme";

/**
 * 管理者ユーザー側のフッター
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ({
                    t,
                }) => {
    const globalThemes = globalTheme._default()
    return (
        <BottomNavigation className={globalThemes.footer}>
            <BottomNavigationAction
                disabled={true}
                label={t('footer.company_name_copyright')}
                showLabel={true}
                style={{color:"#000"}}
            />
            <BottomNavigationAction
                onClick={() => window.open(process.env.REACT_APP_COMPANY_POLICY_POLICY_PAGE, "_blank").focus()}
                label={t('footer.privacy_policy')}
                showLabel={true}
                style={{color:"#000"}}
            />
            <BottomNavigationAction
                onClick={() => window.open(process.env.REACT_APP_COMPANY_SERVICE_POLICY_PAGE, "_blank").focus()}
                label={t('footer.service_policy')}
                showLabel={true}
                style={{color:"#000"}}
            />
        </BottomNavigation>
    );
};

export default withTranslation('common')(Footer);
