import {call, put} from 'redux-saga/effects';
import {
    deleteLoading,
    deleteAddressFailure,
    deleteAddressSuccess,
    deleteAddressDoNotDelete,
    failureAddressList,
    loadedAddressList,
    loadingAddressList,
    loadAddressList,
    registerNewAddressFailure,
    registerNewAddressSuccess,
    addressRegistrationLoad
} from "./actions";
import {addAddresses, callAddresses, deleteAddresses, updateAddresses} from "./api";
import {error_raise} from "../../root/actions";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * 住所取得リクエスト
 * @param data
 */
export function* selectAddressList(data) {
    yield put(loadingAddressList())
    try {
        const addresses = yield call(callAddresses, data.payload.access_token, data.payload?.user_id, null);
        yield put(loadedAddressList(addresses));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(failureAddressList(e));
    }
}

/**
 * 新規住所登録リクエスト
 * @param addressData
 */
export function* addAddressList(addressData) {
    yield put(addressRegistrationLoad());
    try {
        yield call(addAddresses, addressData.payload.address, addressData.payload.access_token, addressData.payload.uuid)
        yield put(registerNewAddressSuccess());
        yield put(loadAddressList(addressData.payload.access_token, addressData.payload.uuid))
    } catch (e) {
        yield put(registerNewAddressFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 住所更新リクエスト
 * @param addressData
 */
export function* updateAddress(addressData) {
    yield put(addressRegistrationLoad())
    try {
        yield call(updateAddresses, addressData.payload.address_id, addressData.payload.access_token, addressData.payload?.current_user_id, addressData.payload.address)
        yield put(registerNewAddressSuccess());
        yield put(loadAddressList(addressData.payload.access_token, addressData.payload?.current_user_id))
    } catch (e) {
        yield put(registerNewAddressFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 住所削除リクエスト
 * @param accountData
 */
export function* deleteAddress(accountData) {
    yield put(deleteLoading())
    try {
        yield call(deleteAddresses, accountData.payload.address, accountData.payload.access_token, accountData.payload.account_id)
        yield put(deleteAddressSuccess())
        yield put(loadAddressList(accountData.payload.access_token, accountData.payload.account_id))
    } catch (e) {
        yield put(deleteAddressFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        } else if(e?.message?.code === "E403_ADDRESS_DO_NOT_DELETE") {
            yield put(deleteAddressDoNotDelete(e))
        }
    }
}

