import {managerUserListOperations} from "../../../../state/ducks/manager/managerUsers";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import UserView from "../../../components/manager/users/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        managerProfile: state.root.managerProfile,

        managerLoading: state.managerUser.managerLoading,
        managerUsers: state.managerUser.managerUserList,
        managerUserList_CurrentPage: state.managerUser.managerUserList_CurrentPage,

        managerIsRegisterView: state.managerUser.managerIsRegisterView,

        managerSearchLoad: state.managerUser.managerSearchLoad,
        managerSearchUserResult: state.managerUser.managerSearchUserResult,
        managerError: state.managerUser.managerError,

        managerRegisterLoading: state.managerUser.managerRegisterLoading,
        managerRegisterError: state.managerUser.managerRegisterError,

        managerActiveId: state.managerUser.managerActiveId,
        managerDeleteUserView: state.managerUser.managerDeleteUserView,
        managerDeleteLoading: state.managerUser.managerDeleteLoading,
        managerDeleteError: state.managerUser.managerDeleteError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    managerViewRegisterToggle: managerUserListOperations.managerViewRegisterToggle,
    managerRegisterNewUser: managerUserListOperations.managerRegisterNewUser,
    managerRequestSearchUsers: managerUserListOperations.managerRequestSearchUsers,
    managerUserChangePage: managerUserListOperations.managerUserChangePage,
    managerViewUserDeleteToggle: managerUserListOperations.managerViewUserDeleteToggle,
    managerDeleteUser: managerUserListOperations.managerDeleteUser,
    managerUpdateUser: managerUserListOperations.managerUpdateUser
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */


/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerUserAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(UserView)

export default ManagerUserAccountContainer;
