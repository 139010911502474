/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

// 一覧取得
export const MANAGER_REQUEST_ACCOUNTS = 'MANAGER_REQUEST_ACCOUNTS';
export const MANAGER_REQUEST_ACCOUNTS_LOADING = 'MANAGER_REQUEST_ACCOUNTS_LOADING';
export const MANAGER_REQUEST_ACCOUNTS_SUCCESS = 'MANAGER_REQUEST_ACCOUNTS_SUCCESS';
export const MANAGER_REQUEST_ACCOUNTS_FAILURE = 'MANAGER_REQUEST_ACCOUNTS_FAILURE';

// モーダル開閉表示
export const MANAGER_ACCOUNT_TOGGLE_REGISTER = 'MANAGER_ACCOUNT_TOGGLE_REGISTER';
export const MANAGER_ACCOUNT_TOGGLE_REGISTER_INIT_SF_ACC_LOAD = 'MANAGER_ACCOUNT_TOGGLE_REGISTER_INIT_SF_ACC_LOAD'
export const MANAGER_ACCOUNT_TOGGLE_EDIT = 'MANAGER_ACCOUNT_TOGGLE_EDIT';
export const MANAGER_TOGGLE_DELETE = 'MANAGER_TOGGLE_DELETE';

// 新規アカウント
export const MANAGER_ADD_ACCOUNT = 'MANAGER_ADD_ACCOUNT';
export const MANAGER_ADD_ACCOUNT_LOADING = 'MANAGER_ADD_ACCOUNT_LOADING';
export const MANAGER_ADD_ACCOUNT_SUCCESS = 'MANAGER_ADD_ACCOUNT_SUCCESS';
export const MANAGER_ADD_ACCOUNT_FAILURE = 'MANAGER_ADD_ACCOUNT_FAILURE';

// 検索
export const MANAGER_SEARCH_ACCOUNT_REQUEST = 'MANAGER_SEARCH_ACCOUNT_REQUEST';
export const MANAGER_SEARCH_ACCOUNT_LOADING = 'MANAGER_SEARCH_ACCOUNT_LOADING';
export const MANAGER_SEARCH_ACCOUNT_SUCCESS = 'MANAGER_SEARCH_ACCOUNT_SUCCESS';
export const MANAGER_SEARCH_ACCOUNT_FAILURE = 'MANAGER_SEARCH_ACCOUNT_FAILURE';

// 削除
export const MANAGER_DELETE_ACCOUNT_REQUEST = 'MANAGER_DELETE_ACCOUNT_REQUEST';
export const MANAGER_DELETE_ACCOUNT_LOADING = 'MANAGER_DELETE_ACCOUNT_LOADING';
export const MANAGER_DELETE_ACCOUNT_SUCCESS = 'MANAGER_DELETE_ACCOUNT_SUCCESS';
export const MANAGER_DELETE_ACCOUNT_FAILURE = 'MANAGER_DELETE_ACCOUNT_FAILURE';

// 編集
export const MANAGER_EDIT_ACCOUNT_REQUEST = 'MANAGER_EDIT_ACCOUNT_REQUEST';
export const MANAGER_EDIT_ACCOUNT_LOADING = 'MANAGER_EDIT_ACCOUNT_LOADING';
export const MANAGER_EDIT_ACCOUNT_SUCCESS = 'MANAGER_EDIT_ACCOUNT_SUCCESS';
export const MANAGER_EDIT_ACCOUNT_FAILURE = 'MANAGER_EDIT_ACCOUNT_FAILURE';

// SFアカウント取得
export const MANAGER_SEARCH_SALESFORCE_ACCOUNT_REQUEST = 'MANAGER_SEARCH_SALESFORCE_ACCOUNT';
export const MANAGER_SEARCH_SALESFORCE_ACCOUNT_SELECT = 'MANAGER_SEARCH_SALESFORCE_ACCOUNT_SELECT';
export const MANAGER_SEARCH_SALESFORCE_ACCOUNT_LOADING = 'MANAGER_SEARCH_SALESFORCE_ACCOUNT_LOADING';
export const MANAGER_SEARCH_SALESFORCE_ACCOUNT_SUCCESS = 'MANAGER_SEARCH_SALESFORCE_ACCOUNT_SUCCESS';
export const MANAGER_SEARCH_SALESFORCE_ACCOUNT_FAILURE = 'MANAGER_SEARCH_SALESFORCE_ACCOUNT_FAILURE';
export const MANAGER_SEARCH_SALESFORCE_ACCOUNT_CLEAR = "MANAGER_SEARCH_SALESFORCE_ACCOUNT_CLEAR";

// SFユーザー取得
export const MANAGER_SEARCH_SALESFORCE_USER_REQUEST = 'MANAGER_SEARCH_SALESFORCE_USER_REQUEST';
export const MANAGER_SEARCH_SALESFORCE_USER_SELECT = 'MANAGER_SEARCH_SALESFORCE_USER_SELECT';
export const MANAGER_SEARCH_SALESFORCE_USER_LOADING = 'MANAGER_SEARCH_SALESFORCE_USER_LOADING';
export const MANAGER_SEARCH_SALESFORCE_USER_SUCCESS = 'MANAGER_SEARCH_SALESFORCE_USER_SUCCESS';
export const MANAGER_SEARCH_SALESFORCE_USER_FAILURE = 'MANAGER_SEARCH_SALESFORCE_USER_FAILURE';
export const MANAGER_SEARCH_SALESFORCE_USER_CLEAR = "MANAGER_SEARCH_SALESFORCE_USER_CLEAR";

// SFユーザー選択
export const MANAGER_SEARCH_SALESFORCE_ACCOUNT_USER_SELECT = "MANAGER_SEARCH_SALESFORCE_ACCOUNT_USER_SELECT";

// 一覧ページ変更
export const MANAGER_CHANGE_ACCOUNT_PAGE = "MANAGER_CHANGE_ACCOUNT_PAGE";

// アカウントのユーザー一覧データの取得
export const MANAGER_REQUEST_ACCOUNT_USERS = "MANAGER_REQUEST_ACCOUNT_USERS"
export const MANAGER_REQUEST_ACCOUNT_USERS_LOADING = "MANAGER_REQUEST_ACCOUNT_USERS_LOADING"
export const MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS = "MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS"
export const MANAGER_REQUEST_ACCOUNT_USERS_FAILURE = "MANAGER_REQUEST_ACCOUNT_USERS_FAILURE"

const types = {
    MANAGER_REQUEST_ACCOUNTS,
    MANAGER_REQUEST_ACCOUNTS_LOADING,
    MANAGER_REQUEST_ACCOUNTS_SUCCESS,
    MANAGER_REQUEST_ACCOUNTS_FAILURE,

    MANAGER_ACCOUNT_TOGGLE_REGISTER,
    MANAGER_ACCOUNT_TOGGLE_REGISTER_INIT_SF_ACC_LOAD,
    MANAGER_ACCOUNT_TOGGLE_EDIT,
    MANAGER_TOGGLE_DELETE,

    MANAGER_ADD_ACCOUNT,
    MANAGER_ADD_ACCOUNT_LOADING,
    MANAGER_ADD_ACCOUNT_SUCCESS,
    MANAGER_ADD_ACCOUNT_FAILURE,

    MANAGER_SEARCH_ACCOUNT_REQUEST,
    MANAGER_SEARCH_ACCOUNT_LOADING,
    MANAGER_SEARCH_ACCOUNT_SUCCESS,
    MANAGER_SEARCH_ACCOUNT_FAILURE,

    MANAGER_DELETE_ACCOUNT_REQUEST,
    MANAGER_DELETE_ACCOUNT_LOADING,
    MANAGER_DELETE_ACCOUNT_SUCCESS,
    MANAGER_DELETE_ACCOUNT_FAILURE,

    MANAGER_EDIT_ACCOUNT_REQUEST,
    MANAGER_EDIT_ACCOUNT_LOADING,
    MANAGER_EDIT_ACCOUNT_SUCCESS,
    MANAGER_EDIT_ACCOUNT_FAILURE,

    MANAGER_SEARCH_SALESFORCE_ACCOUNT_REQUEST,
    MANAGER_SEARCH_SALESFORCE_ACCOUNT_LOADING,
    MANAGER_SEARCH_SALESFORCE_ACCOUNT_SUCCESS,
    MANAGER_SEARCH_SALESFORCE_ACCOUNT_FAILURE,
    MANAGER_SEARCH_SALESFORCE_ACCOUNT_SELECT,

    MANAGER_SEARCH_SALESFORCE_USER_REQUEST,
    MANAGER_SEARCH_SALESFORCE_USER_LOADING,
    MANAGER_SEARCH_SALESFORCE_USER_SUCCESS,
    MANAGER_SEARCH_SALESFORCE_USER_FAILURE,
    MANAGER_SEARCH_SALESFORCE_USER_SELECT,

    MANAGER_SEARCH_SALESFORCE_ACCOUNT_USER_SELECT,
    MANAGER_SEARCH_SALESFORCE_ACCOUNT_CLEAR,
    MANAGER_SEARCH_SALESFORCE_USER_CLEAR,

    MANAGER_CHANGE_ACCOUNT_PAGE,

    MANAGER_REQUEST_ACCOUNT_USERS,
    MANAGER_REQUEST_ACCOUNT_USERS_LOADING,
    MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS,
    MANAGER_REQUEST_ACCOUNT_USERS_FAILURE
};
export default types;
