import React from 'react';
import {Button, Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import {Trans} from 'react-i18next'

/**
 * エラーレイアウト (エラーレイアウト（空テンプレートを表示)
 *
 * @param {string} title - タイトル
 * @param {*} t - 翻訳ファイル
 * @param {Object} error - エラー
 * @param {*} logout - ログアウト関数
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorPage = ({title,  t, logout}) => {
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{minHeight: '100vh'}}
        >
            <Grid item xs={12} md={6}>
                <Card>
                    <CardHeader title={title}/>
                    <CardContent>
                        <Grid item xs={12}>
                            <Typography style={{whiteSpace: "pre-line"}}>
                                <Trans i18nKey={'error.description'} t={t}
                                       contact_mail={process.env.REACT_APP_EBIS_CONTACT_MAIL}>
                                    1 <a style={{color: "initial"}}
                                         href={"mailto:" + process.env.REACT_APP_EBIS_CONTACT_MAIL}>（{{contact_mail: process.env.REACT_APP_EBIS_CONTACT_MAIL}}）</a>
                                    2
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button style={{float: "right", marginBottom: 15}}
                                    onClick={() => logout({returnTo: window.location.origin + "/sign_out"})}
                                    color={"primary"} variant={"contained"}>
                                {t('actions.logout')}
                            </Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ErrorPage;
