/**
 * ///////////////////////////////
 * テーマのタイポグラフィ
 * -------------------------
 *
 * テーマフォントの設定はここで行います
 * ///////////////////////////////
 */
const typography = {
    h1: {
        fontWeight: 500,
        fontSize: 34,
        letterSpacing: '-0.24px'
    },
    h2: {
        fontWeight: "Bold",
        fontSize: 20,
        letterSpacing: '0px',
    },
    h3: {
        fontWeight: "Bold",
        fontSize: 16,
        letterSpacing: '-0.00px'
    },
    h4: {
        fontWeight: 900,
        fontSize: 14,
        letterSpacing: '-0.00px',
        font: "normal normal bold 14px/21px Meiryo;"
    },
    h5: {
        fontWeight: 900,
        fontSize: 16,
        letterSpacing: '-0.05px'
    },
    h6: {
        fontWeight: 500,
        fontSize: 15,
        letterSpacing: '-0.05px'
    },
    body1: {
        fontWeight: 500,
        fontSize: 13,
        letterSpacing: '-0.05px'
    },
    body2: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0
    },
    subtitle1: {},
    subtitle2: {
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0
    },
    fontSize: 13,
    fontFamily: "'メイリオ', 'Meiryo', sans-serif",
    fontWeightHeavy: 900,
    overline: {
        fontWeight: 500
    }
};
export default typography;
