/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const REQUEST_ADDRESSES = 'REQUEST_ADDRESSES'
export const REQUEST_ADDRESSES_LOADING = 'REQUEST_ADDRESSES_LOADING'
export const REQUEST_ADDRESSES_SUCCESS = 'REQUEST_ADDRESSES_SUCCESS'
export const REQUEST_ADDRESSES_FAILURE = 'REQUEST_ADDRESSES_FAILURE'
export const ADDRESS_CHANGE_PAGE = 'MANAGER_CHANGE_PAGE'

export const TOGGLE_ADDRESS_REGISTER = 'TOGGLE_ADDRESS_REGISTER'

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const ADD_ADDRESS_LOADING = 'ADD_ADDRESS_LOADING';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const DELETE_ADDRESS_TOGGLE = "DELETE_ADDRESS_TOGGLE"
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_LOADING = 'DELETE_ADDRESS_LOADING';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';
export const DELETE_DO_NOT_DELETE = 'DELETE_DO_NOT_DELETE';
export const DELETE_ADDRESS_DO_NOT_DELETE_CONFIRM = "DELETE_ADDRESS_DO_NOT_DELETE_CONFIRM";

const types = {
    REQUEST_ADDRESSES,
    REQUEST_ADDRESSES_LOADING,
    REQUEST_ADDRESSES_SUCCESS,
    REQUEST_ADDRESSES_FAILURE,
    ADDRESS_CHANGE_PAGE,

    TOGGLE_ADDRESS_REGISTER,

    ADD_ADDRESS,
    UPDATE_ADDRESS,
    ADD_ADDRESS_LOADING,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAILURE,

    DELETE_ADDRESS_TOGGLE,
    DELETE_ADDRESS,
    DELETE_ADDRESS_LOADING,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAILURE,
    DELETE_DO_NOT_DELETE,
    DELETE_ADDRESS_DO_NOT_DELETE_CONFIRM
};
export default types;
