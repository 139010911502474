import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {managerCommonStyles} from "../../../../theme/manager/styles/common";
import globalTheme from "../../../../theme/globalTheme";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";
import {AccountUserListRow} from "./AccountUserListRow";

/**
 * 管理者側のアカウントのユーザーリストスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pagination: {
        '& .MuiPaginationItem-page': {
            color: "#7E7E7E",
            backgroundColor: "#F2F2F2",
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
        },
    },

}));

/**
 * 管理者側のアカウントのユーザーリスト
 *
 * @param {Object} managerAccountActiveUsers -  アカウントユーザー
 * @param {boolean} managerAccountUsersLoading - リスト読み込みステート
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const AccountUserList =
    ({
         managerAccountActiveUsers,
         managerAccountUsersLoading,
         t
     }) => {

        // アカウントリストのグローバルスタイルクラスの読み込み
        const classes = useStyles();
        const common = managerCommonStyles();
        const _globalTheme = globalTheme._default();
        return (
            <Card
                className={classes.root}
            >
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{fontWeight: 900}}
                            >
                                {!empty(managerAccountActiveUsers?.users) && !empty(managerAccountActiveUsers?.users[0])
                                    ?
                                    t('accounts.users.company_detail', {
                                        company_name: managerAccountActiveUsers.users[0].account.name,
                                        company_id: managerAccountActiveUsers.users[0].account.sf_id
                                    })
                                    :
                                    ""
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{'textAlign': 'right'}}
                            >
                                {t('general.list_amount', {"amount": !empty(managerAccountActiveUsers) && !empty(managerAccountActiveUsers.users) ? managerAccountActiveUsers.users.length : "0"})}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container className={common.tableHeader}>
                    <Grid item xs={6}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.users.list_headers.user_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.users.list_headers.email_address')}
                        </Typography>
                    </Grid>
                </Grid>
                {managerAccountUsersLoading === true ?
                    <Box className={_globalTheme.list} display="flex" alignItems="center">
                        <Grid container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              data-testid="loading"
                        >
                            <Grid item xs={12}>
                                <Loading/>
                            </Grid>
                        </Grid>
                    </Box>
                    : !empty(managerAccountActiveUsers) && !empty(managerAccountActiveUsers.users) &&
                    <Box className={_globalTheme.list} data-testid="has-accounts-user">
                        <AutoSizer>
                            {({height, width}) => (
                                <FixedSizeList
                                    width={width}
                                    height={height}
                                    itemCount={managerAccountActiveUsers.users.length}
                                    itemSize={45}
                                    itemData={{
                                        users: managerAccountActiveUsers.users,
                                        t: t,
                                    }}
                                >
                                    {AccountUserListRow}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </Box>
                }
            </Card>
        );
    };

export default AccountUserList;
