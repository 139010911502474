import {takeEvery, takeLeading} from 'redux-saga/effects';
import {callManagerProfile, sendErrorReport} from './sagas';
import {default as types} from "./types";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* fetchUsers() {
    yield takeLeading(types.GET_MANAGER_PROFILE, callManagerProfile);
    yield takeEvery(types.DEBUG_SEND_ERROR_REPORT, sendErrorReport);
}
