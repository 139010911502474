import ApiUtils from "../../../utils/ApiUtils";
import {
    GET_SERVICE_CONTRACT_API_PATH,
    GET_SERVICE_CONTRACT_CONTACT_ADDRESS_API_PATH,
    GET_SERVICE_CONTRACT_CONTACT_API_PATH,
    GET_SERVICE_CONTRACTS_API_PATH
} from "./constants";
import service_contracts from './schema/service_contracts.json'
import service_contact from './schema/service_contract.json'
import contact from './schema/contact.json'
import contact_address from './schema/contact_with_address.json'
import addresses from './schema/addresses.json'

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /service_contractsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * 利用契約一覧取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - 利用契約ID
 * @param {Object} params - クエリー
 * @returns {AxiosPromise}
 */
export function callServiceContracts(accessToken, accountId, params) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACTS_API_PATH.replace('{UID}', accountId),
        null,
        service_contracts
    )
}

/**
 * 利用契約詳細取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @param {string} serviceContractId - 利用契約ID
 * @returns {AxiosPromise}
 */
export function callSingleServiceContract(accessToken, accountId, serviceContractId) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACT_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId),
        null,
        service_contact
    )
}

/**
 * 利用契約連絡先取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @param {string} serviceContractId - 利用契約ID
 * @param {string} contactId - 連絡先ID
 * @returns {AxiosPromise}
 */
export function callSingleServiceContractContact(accessToken, contactId, serviceContractId, accountId) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACT_CONTACT_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId)
            .replace('{CUID}', contactId),
        null,
        contact
    )
}

/**
 * 利用契約連絡先の更新リクエスト
 * @param {string} contactId - 連絡先ID
 * @param {string} serviceContractId - 利用契約ID
 * @param {string} serviceContractData - 利用契約連絡先更新データ
 * @param {string} accountId - アカウントID
 * @param {string} accessToken - auth0 アクセストークン
 * @returns {AxiosPromise}
 */
export function updateServiceContractApi(contactId, serviceContractId, serviceContractData, accountId, accessToken) {
    return new ApiUtils(accessToken).put(
        GET_SERVICE_CONTRACT_CONTACT_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId)
            .replace('{CUID}', contactId),
        "",
        serviceContractData,
        contact_address
    )
}

/**
 * 利用契約連絡先の取得更新リクエスト
 * @param {string} contactId - 連絡先ID
 * @param {string} serviceContractId - 利用契約ID
 * @param {string} accountId - アカウントID
 * @param {string} accessToken - auth0 アクセストークン
 * @returns {AxiosPromise}
 */
export function callSingleServiceContractContactAddresses(contactId, serviceContractId, accountId, accessToken) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACT_CONTACT_ADDRESS_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId)
            .replace('{CUID}', contactId),
        null,
        addresses
    )
}

/**
 * 利用契約連絡先の住所更新リクエスト
 * @param {string} contactId - 連絡先ID
 * @param {string} serviceContractId - 利用契約ID
 * @param {string} accountId - アカウントID
 * @param {string} accessToken - auth0 アクセストークン
 * @param {Object} addressData - 住所データ
 * @returns {AxiosPromise}
 */
export function updateServiceContractContactAddressApi(contactId, serviceContractId, accountId, accessToken, addressData) {
    return new ApiUtils(accessToken).put(
        GET_SERVICE_CONTRACT_CONTACT_ADDRESS_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId)
            .replace('{CUID}', contactId),
        "",
        addressData,
        contact_address
    )
}
