import {call, put} from "redux-saga/effects";
import {
    failureServiceContractList,
    loadingServiceContractList,
    loadSingleServiceContract,
    loadServiceContractContactAddressFailed,
    loadServiceContractContactAddressLoading,
    loadServiceContractContactAddressSuccess,
    loadServiceContractContactFailed,
    loadServiceContractContactLoading,
    loadServiceContractContactSuccess,
    requestSingleServiceContract,
    singleServiceContractFailed,
    singleServiceContractSuccess,
    serviceContractContactAddressSelect,
    successServiceContractList,
    updateServiceContractContactAddressFailed,
    updateServiceContractContactAddressLoading,
    updateServiceContractContactAddressSuccess,
    updateServiceContractFailed,
    updateServiceContractLoading,
    updateServiceContractSuccess
} from "./actions";
import {error_raise} from "../../root/actions";
import {
    callSingleServiceContract,
    callSingleServiceContractContact,
    callSingleServiceContractContactAddresses,
    callServiceContracts,
    updateServiceContractApi,
    updateServiceContractContactAddressApi
} from "./api";
import {empty} from "../../../utils/Common";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * 利用契約取得リクエスト
 * @param data
 */
export function* selectServiceContracts(data) {
    yield put(loadingServiceContractList())
    try {
        const serviceContracts = yield call(callServiceContracts, data.payload.access_token, data.payload?.user_id, null);
        yield put(successServiceContractList(serviceContracts.data));
    } catch (e) {
        yield put(failureServiceContractList(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 利用契約詳細取得リクエスト
 * @param data
 */
export function* selectSingleServiceContract(data) {
    yield put(loadSingleServiceContract())
    try {
        const serviceContracts = yield call(callSingleServiceContract, data.payload.access_token, data.payload.accountId, data.payload.serviceContractId);
        yield put(singleServiceContractSuccess(serviceContracts.data));
    } catch (e) {
        yield put(singleServiceContractFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 利用契約の連絡先取得リクエスト
 * @param data
 */
export function* selectSingleServiceContractContact(data) {
    yield call(data.payload.toggleEvent())
    yield put(loadServiceContractContactLoading())
    try {
        const contact = yield call(callSingleServiceContractContact, data.payload.accessToken, data.payload.contactId, data.payload.serviceContractId, data.payload.accountId);
        yield put(loadServiceContractContactSuccess(contact.data));

    } catch (e) {
        yield put(loadServiceContractContactFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 利用契約の連絡先更新リクエスト
 * @param data
 */
export function* updateServiceContract(data) {
    yield put(updateServiceContractLoading())
    try {
        yield call(updateServiceContractApi,
            data.payload.contactId,
            data.payload.serviceContractId,
            data.payload.serviceContractData,
            data.payload.accountId,
            data.payload.accessToken
        );
        yield put(updateServiceContractSuccess());
        yield put(requestSingleServiceContract(data.payload.accessToken, data.payload.accountId, data.payload.serviceContractId))
    } catch (e) {
        yield put(updateServiceContractFailed(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 利用契約の連絡先の住所更新リクエスト
 * @param data
 */
export function* updateServiceContractContactAddress(data) {
    yield put(updateServiceContractContactAddressLoading())
    try {
        yield call(updateServiceContractContactAddressApi,
            data.payload.contactId,
            data.payload.serviceContractId,
            data.payload.accountId,
            data.payload.accessToken,
            data.payload.addressData,
        );
        yield put(updateServiceContractContactAddressSuccess());
        yield put(requestSingleServiceContract(data.payload.accessToken, data.payload.accountId, data.payload.serviceContractId))
    } catch (e) {
        yield put(updateServiceContractContactAddressFailed(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 利用契約の連絡先の住所取得リクエスト
 * @param data
 */
export function* selectSingleServiceContractContactAddresses(data) {
    yield call(data.payload.toggleEvent())
    yield put(loadServiceContractContactAddressLoading())
    try {
        const addresses = yield call(callSingleServiceContractContactAddresses, data.payload.contactId, data.payload.serviceContractId, data.payload.accountId, data.payload.accessToken);
        const address_data = addresses.data;
        if (!empty(address_data) && !empty(address_data.addresses)) {
            for (var i = 0; i < address_data.addresses.length; i++) {
                if (address_data.addresses[i].is_selected === true) {
                    yield put(serviceContractContactAddressSelect(address_data.addresses[i].id.toString()));
                }
            }
        }
        yield put(loadServiceContractContactAddressSuccess(addresses.data))
    } catch (e) {
        yield put(loadServiceContractContactAddressFailed(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
