import {Chip, FormControl, FormHelperText, FormLabel, Select} from "@material-ui/core";
import globalTheme from "../theme/globalTheme";
import React from "react";
import {empty} from "../../state/utils/Common";

/**
 * Final FormプラグインとMaterial UI Formフィールドを結合するコンポーネント
 * final formのテキストエリア
 *
 * @param {string} name - 項目フィールドの名前
 * @param {*} onChange - 項目変更イベント
 * @param {string} value - 項目値
 * @param {*} restInput - 他の属性
 * @param {Object} meta - final formメタ情報
 * @param loadingOnDisable - ロード中の状態
 * @param {*} rest - 他の属性
 * @returns {JSX.Element}
 */
 const FinalFormSelect = (
    {
        input: {name, onChange, value, ...restInput},
        meta,
        loadingOnDisable,
        options,
        showError = true,
        t,
        ...rest
    }) => {
        const classes = globalTheme.defaultFormStyle();
        const loadOnDisable = loadingOnDisable && rest.disabled ? classes.loadingOnDisable : undefined
        const defaultFields = globalTheme._default();
        return (
            <div>
                {rest.label &&
                <div>
                    <FormLabel className={loadOnDisable}>
                        {/* この項目は必須ですか？ チェック */}
                        {rest.label}
                        {rest.required &&
                        <Chip label={"必須"} size="small" className={[classes.chip, loadOnDisable].join(" ") }/>
                        }
                    </FormLabel>
                    <br/>
                </div>
                }
                <FormControl variant="outlined" fullWidth size={"small"} style={{marginTop: 5}} className={loadOnDisable}>
                    <Select
                        className={loadOnDisable}
                        name={name}
                        displayEmpty={true}
                        native
                        data-testid={name}
                        onKeyPress={e => {e.key === "Enter" && e.preventDefault()}}
                        disabled={rest.disabled}
                        error={meta.error && meta.touched}
                        inputProps={restInput}
                        onChange={onChange}
                        value={value}
                    >
                        <option key={0} value="">選択してください</option>
                        {!empty(options) && options.map((option) => (
                            <option data-testid={option.value} key={option.id} value={option.value}>{option.translation_view}</option>
                        ))}
                    </Select>
                </FormControl>
                {meta.error && meta.touched && showError &&
                <FormHelperText variant={"filled"} className={defaultFields.error} style={{fontSize: "0.6964285714285714rem"}}>
                    {meta.error}
                </FormHelperText>
                }
            </div>
        )
    }
;

export default FinalFormSelect;
