import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

const Toolbar = ({className, toggleRegisterView, searchLoad, searchUserResult, searchUser, t, ...rest}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Box
                display="flex"
                justifyContent="flex-end"
            >
                {/*<AutoSuggestBox loading={searchLoad} result={searchUserResult} searchEvent={searchUser}*/}
                {/*                labelResult="email"/>*/}
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
