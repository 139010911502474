import React from 'react';
import {Field, Form} from 'react-final-form';
import {Button, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {managerAccountStoreValidation} from "../../../../../state/ducks/manager/managerAccounts/validation";
import FinalFormTextArea from "../../../../atoms/FinalFormTextArea";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import {AutoSuggestBoxStates} from "../../../../atoms/AutoSuggestBoxStates";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {managerAccountForms} from "../../../../../state/ducks/manager/managerAccounts";

/**
 * アカウント作成モーダルスタイル
 * @returns {{transform: string, top: string, left: string}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 *  アカウント作成モーダル
 *
 * @param {boolean} loadingSFUser - 読み込み中のSalesforceユーザー？
 * @param {boolean} loadingSFAccount -  Salesforceのアカウントをロードしていますか？
 * @param {Object} sfAccountError - SalesForceのアカウント検索エラー
 * @param {Object} sfUserError - SalesForceのユーザー検索エラー
 * @param {Object} managerActiveSalesForceAccount - 選択されたSalesForceアカウント
 * @param {Object} managerActiveSalesForceAccountList - 検索語に基づいて選択可能なアカウントのリスト
 * @param {Object} managerActiveSalesForceUser - 選ばれたSalesForceユーザー
 * @param {Object} managerActiveSalesForceUserList - 選択されたアカウントに基づいて選択可能なユーザーのリスト
 * @param managerSearchSalesForceAccount
 * @param {function} managerSelectedSalesForceUser - 選択されたユーザーイベント
 * @param {function} managerSelectedSalesForceAccountClear - 選択したアカウント消去イベント
 * @param {function} managerSelectedSalesForceUserClear - 選択されたユーザー消去のイベント
 * @param {Object} managerAccountRegisterError - リクエストエラーメッセージ
 * @param {boolean} managerAccountIsRegisterView - 作成モーダルは表示されていますか？
 * @param {boolean} managerAccountRegisterLoading - アカウントは登録中？
 * @param {function} managerViewRegisterToggle - モーダルな可視化イベント
 * @param {function} managerRegisterNewAccount - アカウント登録イベント
 * @param {function} managerSelectedSalesForceAccount_LoadUsers - ユーザー獲得時のロードイベント
 * @param {*} t -  翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountCreate = ({
                                  loadingSFUser,
                                  loadingSFAccount,
                                  sfAccountError,
                                  sfUserError,
                                  managerActiveSalesForceAccount,
                                  managerActiveSalesForceAccountList,
                                  managerActiveSalesForceUser,
                                  managerActiveSalesForceUserList,
                                  managerSearchSalesForceAccount,
                                  managerSelectedSalesForceUser,
                                  managerSelectedSalesForceAccountClear,
                                  managerSelectedSalesForceUserClear,
                                  managerAccountRegisterError,

                                  managerAccountIsRegisterView,
                                  managerAccountRegisterLoading,
                                  managerViewRegisterToggle,
                                  managerRegisterNewAccount,
                                  managerSelectedSalesForceAccount_LoadUsers,
                                  t
                              }) => {
    const [modalStyle] = React.useState(getModalStyle);

    // グローバルスタイルの読み込み
    const globalClass = globalTheme._default();
    const formClass = globalTheme.defaultFormStyle();
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently, user} = useAuth0();
    return (
        <Modal open={managerAccountIsRegisterView}
               onClose={managerViewRegisterToggle}
               disableBackdropClick
               className={managerAccountRegisterLoading ? globalClass.loading : undefined}
        >
            <div className={globalClass.modal} style={modalStyle}>
                <Typography variant="h3" style={{'paddingBottom': 30}}
                            id="simple-modal-title">{t('accounts.create.title')}</Typography>
                <Form
                    onSubmit={e => {
                        e.sf_account = managerActiveSalesForceAccount
                        e.sf_account_user = managerActiveSalesForceUser
                        e = mergeWithDefaultForm(e, managerAccountForms.createForm);

                        // 登録がされていない場合
                        if (!managerAccountRegisterLoading) {
                            (async () => {
                               const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                   () => {
                                       return getAccessTokenSilently();
                                   },
                               );
                               e.account = {
                                   uuid: user.sub
                               }
                               managerRegisterNewAccount(e, access_token);
                            })()
                        }
                    }}
                    validate={e => {
                        e.sf_account = managerActiveSalesForceAccount
                        e.sf_account_user = managerActiveSalesForceUser
                        return translate(t, Validation({...e}, managerAccountStoreValidation))
                    }}
                    render={({handleSubmit,  invalid}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    {/* SFアカウント検索コンポーネント */}
                                    <AutoSuggestBoxStates
                                        loading={loadingSFAccount}
                                        required={true}
                                        data-testid={"account"}
                                        requiresEnter={true}
                                        autoHighlight={false}
                                        searchEvent={managerSearchSalesForceAccount}
                                        loadingOnDisable={managerAccountRegisterLoading}
                                        disabled={managerAccountRegisterLoading}
                                        label={t('accounts.create.salesforce_link')}
                                        result={managerActiveSalesForceAccountList}
                                        placeholder={t('accounts.create.salesforce_placeholder')}
                                        customClearEvent={managerSelectedSalesForceAccountClear}
                                        selectValue={() => {
                                            if (!empty(managerActiveSalesForceAccount)) {
                                                return managerActiveSalesForceAccount
                                            }
                                            return null
                                        }}
                                        customOptionSelectedEvent={(option, value) => {
                                            return option[1]["name"] === value["name"]
                                        }}
                                        customSelectEvent={function (value) {
                                            (async () => {
                                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                    () => {
                                                        return getAccessTokenSilently();
                                                    },
                                                );
                                                managerSelectedSalesForceAccount_LoadUsers(value[1], access_token)
                                            })()
                                        }}
                                        labelResult="name"
                                    />
                                </Grid>
                                {/* SFアカウント検索のエラー */}
                                {!empty(sfAccountError) &&
                                <Grid item xs={12} style={{marginTop: 0}}>
                                    <Typography variant={"body1"} className={globalClass.error}>
                                        {t(sfAccountError.translation_error_key)}
                                    </Typography>
                                </Grid>
                                }
                                {/* 備考入力 */}
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="notes"
                                        multiline={5}
                                        disabled={managerAccountRegisterLoading}
                                        loadingOnDisable={managerAccountRegisterLoading}
                                        component={FinalFormTextArea}
                                        type="text"
                                        label={t('accounts.create.note')}
                                    />
                                </Grid>
                                {/* SFユーザー入力の検索 */}
                                <Grid item xs={12}>
                                    <AutoSuggestBoxStates
                                        className={empty(managerActiveSalesForceUserList) ? formClass.disabledInput : ""}
                                        loading={loadingSFUser}
                                        result={managerActiveSalesForceUserList}
                                        placeholder={t('accounts.create.new_user_add_placeholder')}
                                        label={t('accounts.create.new_user_add')}
                                        data-testid={"user"}
                                        autoHighlight={false}
                                        loadingOnDisable={managerAccountRegisterLoading}
                                        description={t('accounts.create.new_user_description')}
                                        additionalEvent={managerSelectedSalesForceUser}
                                        additionalData={managerActiveSalesForceAccount}
                                        customClearEvent={managerSelectedSalesForceUserClear}
                                        customOptionSelectedEvent={(option, value) => {
                                            return option[1]["last_name"] + option[1]["first_name"] === value["last_name"] + value["first_name"]
                                        }}
                                        selectValue={() => {
                                            if (!empty(managerActiveSalesForceUser)) {
                                                return managerActiveSalesForceUser
                                            }
                                            return null
                                        }}
                                        disabled={managerAccountRegisterLoading ||empty(managerActiveSalesForceAccount) || empty(managerActiveSalesForceUserList)}
                                        customSearchEvent={(option => {
                                            console.log(option)
                                            if (empty(option[1])) {
                                                return (option['last_name'] ? option['last_name'] + "　" : "") + (option['first_name'] ? option['first_name'] : "") + "(" + option['email'] + ')'
                                            }
                                            return (option[1]["last_name"] ? option[1]["last_name"] + "　": "") + (option[1]["first_name"] ? option[1]['first_name'] : "") + "(" + option[1]['email'] + ')'
                                        })}
                                        customSelectEvent={function (value) {
                                            if (managerSelectedSalesForceUser) {
                                                managerSelectedSalesForceUser(value[1])
                                            }
                                        }}
                                        labelResult="last_name"
                                    />
                                </Grid>
                                {/* SFユーザーのエラー  */}
                                {!empty(sfUserError) &&
                                <Grid item xs={12} style={{marginTop: 0}}>
                                    <Typography variant={"body1"} className={globalClass.error}>
                                        {t(sfUserError.translation_error_key)}
                                    </Typography>
                                </Grid>
                                }
                                {/* 送信エラー  */}
                                {!empty(managerAccountRegisterError) &&
                                <Grid item xs={12} style={{marginTop: 0}}>
                                    <Typography variant={"body1"} className={globalClass.error}>
                                        {t(managerAccountRegisterError.translation_error_key)}
                                    </Typography>
                                </Grid>
                                }
                                <Grid container item justify="flex-end" spacing={2}>
                                    <Grid item style={{marginTop: 16}}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            disabled={managerAccountRegisterLoading}
                                            onClick={() => managerViewRegisterToggle()}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{marginTop: 16}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={invalid || managerAccountRegisterLoading || empty(managerActiveSalesForceAccount) || empty(managerActiveSalesForceUser)}
                                        >
                                            {managerAccountRegisterLoading ? t('actions.sending') : t('actions.execute')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>)}
                />
            </div>
        </Modal>
    )
};

export default ManagerAccountCreate;
