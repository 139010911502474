import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {Box, Button} from "@material-ui/core";

/**
 * ログアウトボタンの機能
 *
 * @param {string} signOutUri - ログアウト用URL
 * @param {*} t - 翻訳対象
 * @param {*} rest -  追加属性
 * @returns {JSX.Element}
 * @constructor
 */
const LogOutButton = ({signOutUri, t, ...rest}) => {
    const {logout} = useAuth0();
    return (
        <Box p={1} onClick={() => logout({returnTo: window.location.origin + signOutUri})}>
            <Button variant="contained" color={"secondary"} {...rest} >
                {t('actions.logout')}
            </Button>
        </Box>
    )
}

export default LogOutButton;
