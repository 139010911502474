import {takeEvery, takeLeading} from 'redux-saga/effects';
import {
    managerAddUserList,
    managerDeleteUser,
    managerFilterUserList,
    managerSelectUserList,
    managerUpdateUser
} from './sagas';
import {default as types} from "./types";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* managerFetchUsers() {
    yield takeLeading(types.MANAGER_REQUEST_USERS, managerSelectUserList);
    yield takeEvery(types.MANAGER_ADD_USER, managerAddUserList);
    yield takeEvery(types.MANAGER_SEARCH_USER_REQUEST, managerFilterUserList);
    yield takeEvery(types.MANAGER_DELETE_USER_REQUEST, managerDeleteUser)
    yield takeEvery(types.MANAGER_UPDATE_USER_REQUEST, managerUpdateUser)
}
