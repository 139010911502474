/**
 * ////////////////////////////////////////
 * MATERIAL UI THEME OVERRIDES (コンポーネント属性の上書き)
 * ------------------------------------
 *
 * デフォルトのMaterial UIモジュールのスタイルを上書きするモジュール
 * ////////////////////////////////////////
 */
const overrides = {
    MuiButton: { // Name of the component ⚛️ / style sheet
        root: { // Name of the rule
            fontSize: 13, // Some CSS
            border: "1px #CCC solid",

        },
        contained: {
            boxShadow: "None",
            '&:hover': {
                boxShadow: "None"
            }
        },
        containedSecondary: {
            '&:hover': {
                backgroundColor: '#F2F2F2'
            }
        },
        containedPrimary: {
            border: "1px solid rgba(255,255,255,1)",
            '&:hover' : {
                backgroundColor: '#FF8A0A'
            }
        }
    },
    MuiFormLabel: {
        root: {
            color: "#000000",
            fontWeight: 900
        }
    },
    MuiTableCell: {
        head: {
            borderBottom: "0"
        },
        root: {
            borderBottom: "1px #F2F2F2 solid"
        }
    },
    MuiContainer: {
        root: {
            '@media (min-width: 600px)' : {
                paddingLeft: 31,
                paddingRight: 31
            }
        }
    },
    MuiToolbar: {
        gutters: {
            '@media (min-width: 600px)': {
                paddingLeft: 31,
                paddingRight: 31
            }
        }
    },
    MuiDrawer: {
        paperAnchorDockedLeft: {
            borderRight: "1px #CCC solid"
        }
    },
    MuiCardHeader: {
        action: {
            marginTop: "-14px"
        }
    },
    MuiList: {
        padding: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    MuiListItem: {
        gutters: {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
    MuiMenuItem: {
        root: {
            paddingBottom: 4,
            paddingTop: 5
        }
    }
}
export default overrides;
