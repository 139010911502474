import ApiUtils from "../../../utils/ApiUtils";
import {
    MANAGER_GET_ACCOUNT_USERS_API_PATH,
    MANAGER_GET_ACCOUNTS_API_PATH,
    MANAGER_SALESFORCE_ACCOUNT_API_PATH,
    MANAGER_SALESFORCE_USER_API_PATH
} from './constants'
import accountSchema from "./schema/account.json"
import accountsSchema from "./schema/accounts.json"
import salesForceAccountSchema from "./schema/salesforce_account.json"
import salesForceUserSchema from "./schema/salesforce_users.json"
import accountUserSchema from "./schema/account_users.json"
/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /managerAccountsのすべてのAPIイベントは、ここで呼び出されます。
 * これらのメソッドは、主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * アカウント一覧取得リクエスト
 *
 * @param {string} accessToken - auth0 アクセストークン
 * @param {Object} params - GETクエリー
 * @returns {AxiosPromise} - axios 結果
 */
export function managerCallAccounts(accessToken, params) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_ACCOUNTS_API_PATH,
        params,
        accountsSchema
    )
}

/**
 * 新規アカウントリクエスト
 * @param {Object} account - 新規アカウントデータ
 * @param {string} accessToken - アクセストークン
 * @returns {AxiosPromise} - axios 結果
 */
export function managerAddAccounts(account, accessToken) {
    return new ApiUtils(accessToken).post(
        MANAGER_GET_ACCOUNTS_API_PATH,
        account,
        // FIXME: スキーマチェックを追加すること。
        // accountSchema
    )
}

/**
 * アカウント削除リクエスト
 * @param {string} params - アカウントID
 * @param {string} accessToken - アクセストークン
 * @returns {AxiosPromise} - axios 結果
 */
export function managerDeleteAccounts(params, accessToken) {
    return new ApiUtils(accessToken).delete(
        MANAGER_GET_ACCOUNTS_API_PATH,
        params
    )
}

/**
 * アカウント編集リクエスト
 *
 * @param {string} user_id - アカウントID
 * @param {Object} params - 編集データ
 * @param {string} accessToken - auth0 アクセストークン
 * @returns {AxiosPromise} - axios 結果
 */
export function managerUpdateAccount(user_id, params, accessToken) {
    return new ApiUtils(accessToken).put(
        MANAGER_GET_ACCOUNTS_API_PATH,
        user_id,
        params,
        accountSchema
    )
}

/**
 * SFアカウント検索リクエスト
 *
 * @param {string} params - 検索クエリー
 * @param {string} accessToken - auth0 アクセストークン
 * @returns {AxiosPromise} - axios 結果
 */
export function searchSalesForceAccount(params, accessToken) {
    return new ApiUtils(accessToken).get(
        MANAGER_SALESFORCE_ACCOUNT_API_PATH,
        params,
        salesForceAccountSchema
    )
}

/**
 * SFユーザー検索リクエスト
 *
 * @param {string} params - キーワードクエリー
 * @param {string} accessToken - auth0 アクセストークン
 * @param {Object} accountId - SalesForce アカウント
 * @returns {AxiosPromise} - axios 結果
 */
export function searchSalesForceUser(params, accessToken, accountId) {
    return new ApiUtils(accessToken).get(
        MANAGER_SALESFORCE_USER_API_PATH.replace('{SFID}', accountId.sf_id),
        null,
        salesForceUserSchema
    )
}

/**
 * SFアカウントIDでSFアカウントのユーザー取得リクエスト
 *
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function managerCallAccountUsers(accessToken, accountId) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_ACCOUNT_USERS_API_PATH.replace('{SFID}', accountId),
        null,
        accountUserSchema
    )
}
