import ApiUtils from "../../../utils/ApiUtils";
import {GET_ADDRESSES_API_PATH} from './constants'
import addressSchema from "./schema/address.json"
import addressListSchema from "./schema/addresses.json"
/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /addressesのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * 住所一覧取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param userId - ユーザーID
 * @param params - GETクエリー
 * @returns {AxiosPromise}
 */
export function callAddresses(accessToken, userId, params) {
    return new ApiUtils(accessToken).get(
        GET_ADDRESSES_API_PATH.replace('{UID}', userId),
        params,
        addressListSchema
    );
}

/**
 * 新規住所登録リクエスト
 * @param {Object} address - 住所データ
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function addAddresses(address, accessToken, accountId) {
    return new ApiUtils(accessToken).post(
        GET_ADDRESSES_API_PATH.replace('{UID}', accountId),
        address,
        addressSchema
    )
}

/**
 * 住所更新リクエスト
 * @param {string} updateAddressId - アドレスID
 * @param {string} accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @param updateAddress - 住所更新情報
 * @returns {AxiosPromise}
 */
export function updateAddresses(updateAddressId, accessToken, accountId, updateAddress) {
    return new ApiUtils(accessToken).put(
        GET_ADDRESSES_API_PATH.replace('{UID}', accountId),
        updateAddressId,
        updateAddress,
        addressSchema
    )
}

/**
 * 住所削除リクエスト
 * @param {string} deleteAddressId - 削除住所ID
 * @param {string} accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function deleteAddresses(deleteAddressId, accessToken, accountId) {
    return new ApiUtils(accessToken).delete(
        GET_ADDRESSES_API_PATH.replace('{UID}', accountId),
        deleteAddressId
    )
}
