import React from 'react';
import Contents from "../../components/user/Contents";

/**
 * Blank Layout（空テンプレートを表示）
 * ※ 背景機能に使用されます。
 *
 * @param {*} getProfile - 現在のログインユーザー情報を取得するイベント
 * @param {Object} managerProfile - 現在のログインユーザー情報
 * @returns {JSX.Element}
 * @constructor
 */
const BlankLayout = ({getProfile, managerProfile}) => {
    return (
        <div>
            <Contents/>
        </div>
    );
};

export default BlankLayout;
