import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {serviceContractOperations} from "../../../../../state/ducks/user/service_contracts";
import Contact from "../../../../components/user/serviceContracts/contact/Contact";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        activeServiceContract: state.serviceContract.activeServiceContract,
        activeServiceContractContact: state.serviceContract.activeServiceContractContact,
        contactBilledToEditModal: state.serviceContract.contactBilledToEditModal,
        contactSoldToEditModal: state.serviceContract.contactSoldToEditModal,
        editLoading: state.serviceContract.editLoading,
        editError: state.serviceContract.editError,
        contactLoading: state.serviceContract.contactLoading,
        contactEditInitLoad: state.serviceContract.contactEditInitLoad
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadServiceContractList: serviceContractOperations?.loadServiceContractList,

    toggleSoldToModal: serviceContractOperations?.toggleSoldToModal,
    toggleBilledToModal: serviceContractOperations?.toggleBilledToModal,

    updateServiceContractContact: serviceContractOperations?.updateServiceContractContact,
    requestSingleServiceContract: serviceContractOperations?.requestSingleServiceContract,
    loadServiceContractContact: serviceContractOperations?.loadServiceContractContact
}

const _contactContainer = (
    {
        title,
        editTitle,
        contactKey,
        contactData,
        contactEditModal,
        toggleEditContact,


        activeServiceContract,
        activeServiceContractContact,

        loadServiceContractContact,
        contactEditInitLoad,
        updateServiceContractContact,
        editLoading,
        editError,

        managerProfile,
        t
    }) => {
    return (
        <Contact
            serviceContractId={activeServiceContract.service_account_id}
            title={title}
            editTitle={editTitle}
            toggleEditContact={toggleEditContact}
            contactEditModal={contactEditModal}
            contactKey={contactKey}
            loadServiceContractContact={loadServiceContractContact}
            contactEditInitLoad={contactEditInitLoad}
            t={t}
            activeServiceContract={activeServiceContract}
            activeServiceContractContact={activeServiceContractContact}
            accountEditAction={updateServiceContractContact}
            accountEditLoading={editLoading}
            accountEditError={editError}
            activeProfile={managerProfile}
            contactData={contactData}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * コンポーネントに追加する。
 */
const ContactContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_contactContainer)

export default ContactContainer;
