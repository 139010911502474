import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Button, Grid, Modal, Typography} from "@material-ui/core";
import globalTheme from "../theme/globalTheme";
import Auth0Utils from "../../state/utils/Auth0Utils";
import {empty} from "../../state/utils/Common";

/**
 * 削除モーダルでの確認用スタイルクラス
 * @returns {{transform: string, top: string, left: string}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * 削除時の確認モーダル
 * @param {Object} profile - 現在のユーザープロファイル
 * @param {boolean} isOpen - モーダル開閉状態
 * @param {boolean} isLoading - モーダルのロード中 フラグ
 * @param {string} loadingText - 読み込み状態のテキスト（送信ボタンを押したとき
 * @param {*} setClose - イベントでモーダルを閉じます。
 * @param {string} confirmationTitle - 確認用タイトル
 * @param {string} confirmationDescription - 確認事項
 * @param {string} deleteInformation - 情報（ユーザー名、アカウント名）などの削除
 * @param {string} cancelText - キャンセルボタンの文字
 * @param {string} buttonText - 送信ボタンのテキスト
 * @param {*} event - 確認イベント
 * @param {string} error - エラーメッセージ
 * @param {number} id - 関連ID UI
 * @returns {JSX.Element}
 * @constructor
 */
export const ConfirmationModal = (
    {
        profile,
        isOpen,
        isLoading,
        loadingText,
        setClose,
        confirmationTitle,
        confirmationDescription,
        deleteInformation,
        cancelText,
        buttonText,
        event,
        error,
        id,
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // テーマのロード
    const classes = globalTheme._default();
    const globalClass = globalTheme.defaultFormStyle();
    const defaultClass = globalTheme._default();
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={() => setClose({})}
               disableBackdropClick
               className={isLoading ? defaultClass.loading : undefined}
        >
            <div className={classes.modal} style={modalStyle}>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                            style={{'paddingTop': 0}}
                        >
                            {confirmationTitle}
                        </Typography>
                    </Grid>
                    {deleteInformation &&
                    <Grid item xs={12}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{'paddingTop': 10}}
                        >
                            {deleteInformation}
                        </Typography>
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography
                            color="textPrimary"
                            variant="body2"
                            style={{'paddingTop': 5}}
                        >
                            {confirmationDescription}
                        </Typography>
                    </Grid>
                    {!empty(error) &&
                    <Grid item xs={12} style={{marginTop: 0}}>
                        <Typography variant={"body1"} className={defaultClass.error}>
                            {error}
                        </Typography>
                    </Grid>
                    }
                </Grid>
                <Grid item container justify="flex-end" spacing={2}>
                    <Grid item style={{marginTop: 16}}>
                        <Button
                            type="button"
                            color={"secondary"}
                            variant="contained"
                            disabled={isLoading}
                            onClick={() => setClose({})}
                        >
                            {cancelText}
                        </Button>
                    </Grid>
                    <Grid item style={{marginTop: 16}}>
                        <Button
                            onClick={() =>
                                (async () => {
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    event(id, access_token, profile?.account?.uuid);
                                })()
                            }
                            variant="contained"
                            className={globalClass.warningButton}
                            disabled={isLoading}
                            type="submit"
                        >
                            {isLoading === true ? loadingText : buttonText}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
}
