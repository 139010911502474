import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";

/**
 * レンダリングはしないが、auth0サインアウトプロセスを初期化するコンテナ
 * @returns {JSX.Element}
 * @constructor
 */
const UserLoginContainer = () => {
    const {
        loginWithRedirect,
    } = useAuth0();

    useEffect(loginWithRedirect, [loginWithRedirect])

    return (
        <div>
        </div>
    )
}
export default UserLoginContainer
