import validate from "validate.js";
import {empty, stringIndexSearch} from "./Common";

/***
 * ////////////////////////////////
 * バリデーションユーティリティーヘルパー(ansman/validate.js を使用)
 * ----------------------
 *
 * このプロジェクトのすべてのフォームを妥当性確認するユーティリティです。
 * そして、フォームのキーを読み取り可能なエラーメッセージに変換します。
 * @dependency https://github.com/ansman/validate.js
 * ////////////////////////////////
 */

/**
 * フィールドが空であるかどうかをチェックするバリデーションチェッカ
 * 翻訳されるために、メッセージを書き換える
 */
validate.validators.presence.options =
    {
        message: function (value, attribute) {
            return validate.format("^%{attribute}%{required}", {
                attribute: "{label_name." + attribute + "}",
                required: "{validation.required}"
            });
        },
        allowEmpty: false
    };

/**
 * 数字チェッカー
 */
validate.validators.numericality.options = {message: '^validator.number_error'}

/**
 * メールチェッカー
 */
validate.validators.email.options = {message: '^validator.email_error'}

/**
 * 最大文字数制限チェッカー
 */
validate.validators.length.options = {
    message: function (value, options, attribute) {
        // 正確な文字の長さ
        if(!empty(attribute) && 'is' in attribute) {
            return validate.format("^%{exact}%{exact_length}", {
                exact: "{" + attribute?.is.toString() + "}",
                exact_length: "{validation.not_exact}"
            })
        }
        // 最大文字数
        return validate.format("^%{limit}%{max_length}", {
            limit: "{" + attribute?.maximum.toString() + "}",
            max_length: "{validation.max_length}"
        })
    }
}

/**
 * フィールドは "どちらか "が提供されている場合、必須でなければならない」カスタムバリデーション
 *
 * @param value
 * @param options
 * @param attribute
 * @param attributes
 * @param globalOptions
 * @returns {string}
 */
validate.validators.eitherFieldRequired = function (value, options, attribute, attributes, globalOptions) {
    if (empty(options.compareField)) {
        return "^validator.invalid_compare_field";
    }
    if (empty(value) && empty(attributes[options.compareField])) {
        return "^validator.telephone_number_required"
    }
}

/**
 * カスタム電話妥当性確認オブジェクト
 *
 * @param value
 * @param options
 * @param attribute
 * @param attributes
 * @param globalOptions
 * @returns {string}
 */
validate.validators.phone_validation = function (value, options, attribute, attributes, globalOptions) {
    if(!empty(value)) {
        const regSyntax = new RegExp(/^[0-9 -]+$/);
        if(!regSyntax.test(value)) {
            return "^validator.telephone_format"
        }
    }
}

/**
 * i18nextオブジェクトであらゆるバリデーションエラーを翻訳
 * @param {*} translation i18next.t Object
 * @param {Object} translationObject すべての妥当性確認エラーオブジェクト
 * @returns {*}
 */
export const translate = (translation, translationObject) => {
    if (!empty(translationObject)) {
        Object.keys(translationObject).forEach(function (key, value) {
            if (!empty(translationObject[key][0]) && translationObject[key][0].includes("{")) {
                let translatedString = ""
                // ラベルと入力欄の翻訳機能
                stringIndexSearch('{', translationObject[key][0]).forEach((v) => {
                    translatedString = translatedString +
                        translation(
                            translationObject[key][0].substr(
                                v + 1,
                                translationObject[key][0].slice(v).indexOf('}') - 1
                            )
                        )
                })
                translationObject[key] = translatedString;
            } else {
                // メッセージだけの翻訳
                translationObject[key] = translation(translationObject[key])
            }
        });
        return translationObject;
    }
}
export default validate;
