import React from 'react';
import {Field, Form} from 'react-final-form';
import {Button, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {userStoreValidation} from "../../../../../state/ducks/user/users/validation";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {userForms} from "../../../../../state/ducks/user/users";

/**
 * コンポーネントスタイル
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * ユーザーの新規・更新モーダル.
 *
 * @param className - 親のコンポーネントスタイル
 * @param {boolean} isOpen - モーダルの表示・非表示状態
 * @param {managerViewRegisterToggle} setClose - 非表示アクション
 * @param {Object} activeUser - (編集モード) 選択したユーザー情報
 * @param {managerRegisterNewUser} userRegister - 新規ユーザー登録リクエスト
 * @param {boolean} RegisterLoading -ユーザー登録・編集のロード中状態
 * @param {Object} RegisterError - 登録・更新リクエストエラー
 * @param {managerUpdateUser} updateUser - 更新ユーザーリクエスト
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const UserModal = (
    {
        isOpen,
        setClose,
        activeUser,
        userRegister,
        userEdit,
        registerLoading,
        registerError,
        profile,
        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // アプリケーションテーマ
    const globalStyle = globalTheme._default();
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={() => setClose({})}
               disableBackdropClick
               className={registerLoading ? globalStyle.loading : undefined}
        >
            <div className={globalStyle.modal} style={modalStyle}>
                <Typography variant="h2" style={{'paddingBottom': 30}}
                            id="simple-modal-title">{!empty(activeUser) ? t('users.update.title') : t('users.create.title')}</Typography>
                <Form
                    onSubmit={async (e) => {
                        e = mergeWithDefaultForm(e, userForms.createEditForm)
                        // 現在の選択したユーザーの存在があるか上で編集リクエストを投げる。
                        // いない場合、新規ユーザーリクエストを投げる
                        if (empty(activeUser)) {
                            // 新規ユーザーリクエスト
                            if (!registerLoading) {
                                 const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                    () => {
                                        return getAccessTokenSilently();
                                    },
                                );
                                await userRegister(e, access_token, profile?.account?.uuid);
                            }
                        } else {
                            // 更新ユーザーリクエスト
                            if (!registerLoading) {
                                e.uuid = activeUser.uuid;
                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                    () => {
                                        return getAccessTokenSilently();
                                    },
                                );
                                await userEdit(activeUser, e, access_token, profile?.account?.uuid);
                            }
                        }
                    }}
                    // ここでフォームデータを妥当性確認し、キーを変換します。
                    validate={e => {
                        return translate(t, Validation({...e}, userStoreValidation))
                    }}
                    // （編集モード）最初のバリュー
                    initialValues={{
                        last_name: !empty(activeUser) ? activeUser.last_name : "",
                        first_name: !empty(activeUser) ? activeUser.first_name : "",
                        email: !empty(activeUser) ? activeUser.email : ""
                    }}
                    // ここでは、フォームのレンダリングと制御を行います
                    // エラー処理やダブルクリック防止などはここで行います
                    render={({handleSubmit, form, pristine, invalid, values}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        size={"small"}
                                        name="last_name"
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('users.last_name')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        size={"small"}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        value={!empty(activeUser) && activeUser.first_name}
                                        name="first_name"
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('users.first_name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="email"
                                        fullWidth
                                        required
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        size={"small"}
                                        value={!empty(activeUser) && activeUser.email}
                                        component={FinalFormTextField}
                                        type="email"
                                        label={t('users.email_address')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={globalStyle.error}>
                                        {t(registerError?.translation_error_key)}
                                    </Typography>
                                </Grid>
                                <Grid item container spacing={3} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            disabled={registerLoading}
                                            onClick={() => setClose({})}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={invalid || pristine || registerLoading}
                                        >
                                            {registerLoading ? t('actions.sending') : !empty(activeUser) ? t('actions.change') : t('actions.add')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>)}
                />
            </div>

        </Modal>
    )
};

export default UserModal;
