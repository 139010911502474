import {Box, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import ExpandDropDown from "../../../../atoms/ExpandDropDown";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";

export const AccountsListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.accounts[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={1}>
                <Link
                    to={'/manager/accounts/' + props.data.accounts[props.index].uuid}>
                    <Typography className={commonTheme.link} variant="body1">
                        {props.data.accounts[props.index].id}
                    </Typography>
                </Link>
            </Grid>
            <Grid item xs={4}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.accounts[props.index].name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.accounts[props.index].sf_id}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.accounts[props.index].note && props.data.accounts[props.index].note.length > 30 ?
                        props.data.accounts[props.index].note.substring(0, 30) + '...' :
                        props.data.accounts[props.index].note
                    }
                </Typography>
            </Grid>
            <Grid item xs={1} style={{marginTop: -5}}>
                <Box
                    justifyContent="flex-end"
                    display="flex"
                >
                    {/**
                     * 削除ユーザー, 編集ユーザーをドロップダウンアトムコンポーネントに追加します。
                     **/}
                    <ExpandDropDown
                        menuItems={
                            [
                                {
                                    name: props.data.t('actions.edit'),
                                    event: () => props.data.handleEditClick(props.data.accounts[props.index])
                                },
                                {
                                    name: props.data.t('actions.delete'),
                                    event: () => props.data.handleDeleteClick(props.data.accounts[props.index])
                                }
                            ]
                        }/>
                </Box>
            </Grid>
        </Grid>
    )
}
