import {Redirect, Route, Switch} from "react-router-dom";
import {ProtectedRoute} from "../../PrivateRoute";

import React from "react";
import UserAccountContainer from "./user/users/Users";
import AddressAccountContainer from "./user/addresses/Addresses";
import ManagerUserAccountContainer from "./manager/users/Users";
import ManagerAccountContainer from "./manager/accounts/Accounts";
import UserLoginContainer from "./user/UserLoginContainer";
import ManagerLoginContainer from "./manager/ManagerLoginContainer";
import ServiceContractsContainer from "./user/serviceContracts/ServiceContracts";
import ContentsContainer from "./user/serviceContracts/Contacts";
import managerAccountUserContainer from "./manager/accounts/Users";

/**
 * urlに基づいてコンテナをロードする
 * @returns {JSX.Element}
 * @constructor
 */
const RouteContainer = () => {
    return (
        <div className="makeStyles-content-4">
            <Switch>
                <Route exact path="/" component={UserLoginContainer}/>

                <ProtectedRoute path="/users" redirectTo="/" component={UserAccountContainer}/>
                <ProtectedRoute path="/accounts" redirectTo="/" component={AddressAccountContainer}/>
                <ProtectedRoute path="/service_contracts" redirectTo="/" component={ServiceContractsContainer}/>
                <ProtectedRoute path="/contacts/:id" redirectTo="/" component={ContentsContainer}/>

                <Route exact path="/manager" component={ManagerLoginContainer}/>

                <ProtectedRoute path="/manager/users" redirectTo="/manager" component={ManagerUserAccountContainer}/>
                <ProtectedRoute path="/manager/accounts/:id" redirectTo="/manager"
                                component={managerAccountUserContainer}/>
                <ProtectedRoute path="/manager/accounts" redirectTo="/manager" component={ManagerAccountContainer}/>
                <Redirect to="/"/>
            </Switch>
        </div>
    )
}
export default RouteContainer
