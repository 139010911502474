import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// 住所一覧
//-------------------------------------------------

/**
 * 住所一覧取得リクエスト
 * @param {string} access_token - auth0アクセストークン
 * @param {string} user_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, user_id}, type: string}}
 */
export function loadAddressList(access_token, user_id) {
    return {
        type: types.REQUEST_ADDRESSES,
        payload: {
            access_token: access_token,
            user_id: user_id
        }
    };
}

/**
 * 住所一覧取得リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadingAddressList() {
    return {
        type: types.REQUEST_ADDRESSES_LOADING
    }
}

/***
 * 住所一覧取得リクエスト（成功）
 * @param {Object} addresses 取得した住所データ
 * @returns {{payload, type: string}}
 */
export function loadedAddressList(addresses) {
    return {
        type: types.REQUEST_ADDRESSES_SUCCESS,
        payload: addresses
    }
}

/***
 * 住所一覧取得リクエスト（失敗)
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function failureAddressList(error) {
    return {
        type: types.REQUEST_ADDRESSES_FAILURE,
        payload: {
            error: error
        }
    }
}

//-------------------------------------------------
// 住所一覧のページネーション
//-------------------------------------------------

/***
 *  住所一覧のページ変更
 * @param {number} PageNo
 * @returns {{payload: {page_no}, type: string}}
 */
export function addressChangePage(PageNo) {
    return {
        type: types.ADDRESS_CHANGE_PAGE,
        payload: {
            page_no: PageNo
        }
    }
}

//-------------------------------------------------
// 住所登録
//-------------------------------------------------

/***
 * 新規住所登録モーダル表示の開閉
 * @param {Object} activeAddressid - （編集モードのみ）選択した住所
 * @returns {{payload: {address_id: {}}, type: string}}
 */
export function viewRegisterToggle(activeAddressid = {}) {
    return {
        type: types.TOGGLE_ADDRESS_REGISTER,
        payload: {
            address_id: activeAddressid
        }
    }
}

/***
 * 新規住所追加リクエスト
 * @param {Object} address - 新規住所データ
 * @param {string} access_token - auth0アクセストークン
 * @param {string} current_user_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, address, uuid}, type: string}}
 */
export function registerNewAddress(address, access_token, current_user_id) {
    return {
        type: types.ADD_ADDRESS,
        payload: {
            address: address,
            access_token: access_token,
            uuid: current_user_id
        }
    }
}

//-------------------------------------------------
// 住所更新
//-------------------------------------------------

/***
 * ユーザー情報の更新リクエスト
 * @param {Object} address - （編集モードのみ）変更住所
 * @param {Object} new_address_info - 変更・新規住所データ
 * @param {string} access_token - auth0 アクセストークン
 * @param {string} current_user_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, address_id: *, current_user_id, address}, type: string}}
 */
export function updateAddress(address, new_address_info, access_token, current_user_id) {
    return {
        type: types.UPDATE_ADDRESS,
        payload: {
            address: new_address_info,
            address_id: address?.id,
            access_token: access_token,
            current_user_id: current_user_id
        }
    }
}

//****---------------------------------------------
// 住所更新はここまで
//****---------------------------------------------

/***
 * 新規住所登録・住所更新リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function addressRegistrationLoad() {
    return {
        type: types.ADD_ADDRESS_LOADING
    }
}

/***
 * 新規住所・住所更新リクエスト（成功）
 * @returns {{type: string}}
 */
export function registerNewAddressSuccess() {
    return {
        type: types.ADD_ADDRESS_SUCCESS
    }
}

/***
 * 新規住所・住所更新リクエスト（失敗）
 * @param {Object} e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function registerNewAddressFailure(e) {
    return {
        type: types.ADD_ADDRESS_FAILURE,
        payload: {error: e}
    }
}

//-------------------------------------------------
// 住所削除
//-------------------------------------------------

/***
 * 住所削除モーダル開閉
 * @param {string} accountId - ログインしたユーザーのアカウントID
 * @returns {{payload: {accountId}, type: string}}
 */
export function viewAddressDeleteToggle(accountId) {
    return {
        type: types.DELETE_ADDRESS_TOGGLE,
        payload: {
            accountId: accountId
        }
    }
}

/***
 * 住所削除リクエスト
 * @param address - 削除住所ID
 * @param access_token - auth0 アクセストークン
 * @param account_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, account_id, address}, type: string}}
 */
export function deleteAddresses(address, access_token, account_id) {
    return {
        type: types.DELETE_ADDRESS,
        payload: {
            address: address,
            access_token: access_token,
            account_id: account_id
        }
    }
}

/***
 * 住所削除リクエスト(ロード中）
 * @returns {{type: string}}
 */
export function deleteLoading() {
    return {
        type: types.DELETE_ADDRESS_LOADING
    }
}

/***
 * ユーザー削除リクエスト(成功）
 * @returns {{type: string}}
 */
export function deleteAddressSuccess() {
    return {
        type: types.DELETE_ADDRESS_SUCCESS
    }
}

/***
 * ユーザー削除リクエスト(失敗）
 * @param {object} e - エラー内容
 * @returns {{payload, type: string}}
 */
export function deleteAddressFailure(e) {
    return {
        type: types.DELETE_ADDRESS_FAILURE,
        payload: e
    }
}

/***
 * ユーザー削除リクエスト（契約に紐づく住所の削除拒否）
 * @param {object} e - エラー内容
 * @returns {{payload, type: string}}
 */
export function deleteAddressDoNotDelete(e) {
    return {
        type: types.DELETE_DO_NOT_DELETE,
        payload: {error: e}
    }
}

/**
 * 削除注意モダールを閉じるアクション。
 * @returns {{type: string}}
 */
export function confirmDeleteAddressDoNotDelete() {
    return {
        type: types.DELETE_ADDRESS_DO_NOT_DELETE_CONFIRM
    }
}


const actions = {

    loadAddressList,
    loadingAddressList,
    loadedAddressList,
    failureAddressList,
    addressChangePage,

    viewRegisterToggle,

    registerNewAddress,
    updateAddress,
    addressRegistrationLoad,
    registerNewAddressSuccess,
    registerNewAddressFailure,

    viewAddressDeleteToggle,
    deleteAddresses,
    deleteLoading,
    deleteAddressSuccess,
    deleteAddressFailure,
    deleteAddressDoNotDelete,
    confirmDeleteAddressDoNotDelete,
};
export default actions;
