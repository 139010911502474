import {withAuthenticationRequired} from "@auth0/auth0-react";
import React from "react";
import {Route} from "react-router-dom";
import FullScreenLoading from "./views/atoms/FullScreenLoading";

/**
 * 保護されたルートにログインしている場合
 * ログインしていないユーザーは、指定されたURLにリダイレクトされます。
 * ※ 注: auth0では、許可されたログアウトURLと指定されたURLが一致しない場合、auth0エラーが発生します。
 *
 * @param component
 * @param redirectTo
 * @param args
 * @returns {JSX.Element}
 * @constructor
 */
export function ProtectedRoute({component, redirectTo, ...args}) {
    return (
        <Route component={withAuthenticationRequired(component, {
            onRedirecting: () => <FullScreenLoading/>
        })} {...args} />
    );
}
