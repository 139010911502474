import ApiUtils from "../../../utils/ApiUtils";
import {GET_USERS_API_PATH} from './constants'
import _users from "./schema/users.json";
import _user from "./schema/user.json"

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /usersのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * ユーザー一覧取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param userId - ユーザーID
 * @param params - GETクエリー
 * @returns {AxiosPromise}
 */
export function callUsers(accessToken, userId, params) {
    return new ApiUtils(accessToken).get(
        GET_USERS_API_PATH.replace('{UID}', userId),
        params,
        _users
    );
}

/**
 * 新規ユーザーリクエスト
 * @param {Object} user - ユーザーデータ
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function addUsers(user, accessToken, accountId) {
    return new ApiUtils(accessToken).post(
        GET_USERS_API_PATH.replace('{UID}', accountId),
        user,
        _user
    )
}

/**
 * ユーザー更新リクエスト
 * @param {string} updateUserId - ユーザーID
 * @param {string} accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @param updateUser - ユーザー更新情報
 * @returns {AxiosPromise}
 */
export function updateUsers(updateUserId, accessToken, accountId, updateUser) {
    return new ApiUtils(accessToken).put(
        GET_USERS_API_PATH.replace('{UID}', accountId),
        updateUserId,
        updateUser,
        _user
    )
}

/**
 * ユーザー削除リクエスト
 * @param {string} deleteUserId - 削除ユーザーID
 * @param {string} accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function deleteUsers(deleteUserId, accessToken, accountId) {
    return new ApiUtils(accessToken).delete(
        GET_USERS_API_PATH.replace('{UID}', accountId),
        deleteUserId
    )
}
