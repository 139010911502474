/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（serviceContracts）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const serviceContractWatcherSagas = Object.values(watchersSagas);
export {default as serviceContractSelectors} from "./selectors";
export {default as serviceContractOperations} from "./operations";
export {default as serviceContractTypes} from "./types";
export {default as serviceContractForms} from "./forms"

export {serviceContractState, default as serviceContractReducer} from './reducers';
