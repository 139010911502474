import React from 'react';
import {Field, Form} from 'react-final-form';
import {Box, Button, Chip, FormLabel, Grid, Modal, Typography} from "@material-ui/core";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {useAuth0} from "@auth0/auth0-react";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import {contactValidation} from "../../../../../state/ducks/user/service_contracts/validation";
import Loading from "../../../../atoms/Loading";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {serviceContractForms} from "../../../../../state/ducks/user/service_contracts";

/**
 * コンポーネントスタイルクラス
 * @returns {{transform: string, top: string, left: string}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * 利用契約の連絡先編集モーダル
 *
 * @param {boolean} isOpen - モーダル表示・非表示状態
 * @param {string} title - モーダルタイトル
 * @param {*} setClose - モーダル非表示リクエスト
 * @param {*} account - 利用契約情報
 * @param {Object} activeServiceContractContact - 選択した利用契約の連絡先
 * @param {boolean} contactEditInitLoad - 最初モーダルのロードフラグ
 * @param {string} contactKey - 連絡先種類 (アカウント担当者|請求担当者)
 * @param {*} accountEdit - 連絡先情報更新アクション
 * @param {boolean} registerLoading - 連絡先情報更新中フラグ
 * @param {Object} registerError - 連絡先登録
 * @param {Object} profile  - ロードしているユーザー情報
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ContactEditModal = (
    {
        isOpen,
        title,
        setClose,
        account,
        activeServiceContractContact,
        contactEditInitLoad,
        contactKey,
        accountEdit,
        registerLoading,
        registerError,
        profile,
        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // グローバルスタイル
    const globalClass = globalTheme._default()
    const globalStyle = globalTheme.defaultFormStyle();
    // auth0 アクセストークンの取得リクエスト
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={() => setClose({})}
               disableBackdropClick
               className={registerLoading ? globalClass.loading : undefined}
        >
            <div className={globalClass.modal} style={modalStyle}>
                <Typography variant="h3" style={{'paddingBottom': 30}} id="simple-modal-title">{title}</Typography>
                {/* モーダルのロード状態  */}
                {contactEditInitLoad === true ?
                    <Box spacing={3}>
                        <Loading/>
                    </Box>
                    :
                    <Form
                        onSubmit={async (e) => {
                            e = mergeWithDefaultForm(e, serviceContractForms.contactEditForm);
                            if (!empty(account) && !registerLoading) {
                                // 利用契約の連絡先編集
                                if (!registerLoading) {
                                    const updatedData = {
                                        ...activeServiceContractContact,
                                        department: e.department,
                                        first_name: e.first_name,
                                        last_name: e.last_name,
                                        mobile_phone: e.mobile_phone,
                                        email: e.email,
                                        work_phone: e.work_phone,
                                        contact_type: contactKey
                                    };
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    await accountEdit(activeServiceContractContact.uuid, account.uuid, profile?.account?.uuid, updatedData, access_token);
                                }
                            }
                        }}
                        // フォーム妥当性確認
                        validate={e => {
                            return translate(t, Validation({...e}, contactValidation))
                        }}
                        // 最初のフォームバリュー
                        initialValues={{
                            last_name: !empty(activeServiceContractContact) ? activeServiceContractContact.last_name : "",
                            first_name: !empty(activeServiceContractContact) ? activeServiceContractContact.first_name : "",
                            work_phone: !empty(activeServiceContractContact) ? activeServiceContractContact.work_phone : "",
                            mobile_phone: !empty(activeServiceContractContact) ? activeServiceContractContact.mobile_phone : "",
                            email: !empty(activeServiceContractContact) ? activeServiceContractContact.email : "",
                            department: !empty(activeServiceContractContact) ? activeServiceContractContact.department : ""
                        }}
                        render={({handleSubmit, form, submitting, pristine, invalid, values}) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            fullWidth
                                            required
                                            loadingOnDisable={registerLoading}
                                            disabled={registerLoading}
                                            size={"small"}
                                            name="last_name"
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.last_name')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            fullWidth
                                            required
                                            loadingOnDisable={registerLoading}
                                            disabled={registerLoading}
                                            size={"small"}
                                            name="first_name"
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.first_name')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>
                                            {t('general.phone_number')}
                                            <Chip label={"必須"} size="small" className={globalStyle.chip}/>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="work_phone"
                                            fullWidth
                                            loadingOnDisable={registerLoading}
                                            disabled={registerLoading}
                                            size={"small"}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.work_phone')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="mobile_phone"
                                            fullWidth
                                            loadingOnDisable={registerLoading}
                                            disabled={registerLoading}
                                            size={"small"}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.mobile_phone')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{color: "#7E7E7E"}}>
                                            {t('service_contracts.detail.phone_warning')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="email"
                                            fullWidth
                                            required
                                            loadingOnDisable={registerLoading}
                                            disabled={registerLoading}
                                            size={"small"}
                                            component={FinalFormTextField}
                                            type="email"
                                            label={t('service_contracts.detail.modal.email')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="department"
                                            fullWidth
                                            loadingOnDisable={registerLoading}
                                            disabled={registerLoading}
                                            size={"small"}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.department')}
                                        />
                                    </Grid>
                                    {registerError?.translation_error_key &&
                                        <Grid item xs={12}>
                                            <Typography className={globalClass.error}>
                                                {t(registerError?.translation_error_key)}
                                            </Typography>
                                        </Grid>
                                    }
                                    {activeServiceContractContact?.status === false &&
                                        <Grid item xs={12}>
                                            <Typography className={globalClass.error} data-testid={'cannot_change'}>
                                                {t('service_contracts.detail.modal.cancel_type.' + activeServiceContractContact?.ebis_cancel_type)}{t('service_contracts.detail.modal.update_deny_reason')}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item container spacing={3} justify="flex-end">
                                        <Grid item>
                                            <Button
                                                type="button"
                                                color={"secondary"}
                                                variant="contained"
                                                disabled={registerLoading}
                                                onClick={() => setClose({})}
                                            >
                                                {t('actions.cancel')}
                                            </Button>
                                        </Grid>
                                        {activeServiceContractContact?.status !== false &&
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={invalid || pristine || registerLoading}
                                                >
                                                    {registerLoading ? t('actions.sending') : t('actions.change')}
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>

                                </Grid>
                            </form>)}
                    />
                }
            </div>

        </Modal>
    )
};

export default ContactEditModal;
