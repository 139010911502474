import React, {useEffect} from 'react';
import {Box, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import UsersList from "./list/UsersList";
import Toolbar from "./Toolbar";
import {managerLoadUserList} from "../../../../state/ducks/manager/managerUsers/actions";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import UserModal from "./modal/UserModal";
import {empty} from "../../../../state/utils/Common";
import {ConfirmationModal} from "../../../atoms/ConfirmationModal";
import Auth0Utils from "../../../../state/utils/Auth0Utils";

/**
 * /manager/users コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /usersのメインコンポーネント
 * @param {Object} error - ページエラーの詳細
 * @param {boolean} reLoad - ダブルロードチェッカー
 * @param {Object} managerUsers - 現在のロードされたユーザー
 * @param {boolean} managerLoading - "Is the list loading?" flag
 * @param {boolean} managerIsRegisterView = 一覧が読み込まれていますか？ フラグ
 * @param {Object} managerProfile - ログインしたユーザーのプロフィール
 * @param {managerViewRegisterToggle} managerViewRegisterToggle - 登録モーダルのトグル
 * @param {managerRegisterNewUser} managerRegisterNewUser - 新規ユーザー登録アクション
 * @param {managerSearchLoad} managerSearchLoad - 検索時にアクションを読み込む
 * @param {Object} managerSearchUserResult - 検索結果からのユーザーリスト
 * @param {managerRequestSearchUsers} managerRequestSearchUsers - 検索イベント
 * @param {boolean} managerRegisterLoading - 新規ユーザー登録の読み込み
 * @param {Object} managerRegisterError - 新規ユーザー登録エラー
 * @param {Object} managerActiveId - 管理者ID
 * @param {boolean} managerDeleteUserView - 削除モーダルが開いているか？ フラグ
 * @param {boolean} managerDeleteLoading - 削除処理？ フラグ
 * @param {Object} managerDeleteError - 削除エラー
 * @param {managerViewUserDeleteToggle} managerViewUserDeleteToggle - 削除モーダル表示のトグルイベント
 * @param {managerDeleteUser} managerDeleteUser - ユーザー削除イベント
 * @param {managerUpdateUser} managerUpdateUser - ユーザー更新イベント
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const UserView = ({
                      error,
                      reLoad,
                      managerUsers,
                      managerLoading,
                      managerIsRegisterView,
                      managerProfile,
                      managerViewRegisterToggle,
                      managerRegisterNewUser,
                      managerSearchLoad,
                      managerSearchUserResult,
                      managerRequestSearchUsers,
                      managerRegisterLoading,
                      managerRegisterError,
                      managerActiveId,
                      managerDeleteUserView,
                      managerDeleteLoading,
                      managerDeleteError,
                      managerViewUserDeleteToggle,
                      managerDeleteUser,
                      managerUpdateUser,
                      t,
                  }) => {
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();
    // ロードReduxディスパッチ
    const dispatch = useDispatch();
    /**
     * 初回ロード時に、APIからユーザーリストを呼び出す
     */
    useEffect(() => {
        if (!empty(managerProfile) && reLoad) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                dispatch(managerLoadUserList(access_token));
            })()
        }
    }, [dispatch, getAccessTokenSilently, managerProfile, reLoad])
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('users.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2">{t('users.title')}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            data-testid="create-button"
                            onClick={() => managerViewRegisterToggle(null)}
                        >
                            {t('users.new_account')}
                        </Button>
                    </Grid>
                </Grid>
                {/* 作成・編集 モーダルコンポーネント */}
                <UserModal
                    isOpen={managerIsRegisterView}
                    setClose={managerViewRegisterToggle}
                    userRegister={managerRegisterNewUser}
                    activeUser={managerActiveId}
                    RegisterError={managerRegisterError}
                    RegisterLoading={managerRegisterLoading}
                    updateUser={managerUpdateUser}
                    t={t}
                />
                {/* 削除モーダルコンポーネント  */}
                <ConfirmationModal
                    isOpen={managerDeleteUserView}
                    setClose={managerViewUserDeleteToggle}
                    confirmationTitle={t('users.delete.title')}
                    confirmationDescription={t('users.delete.description')}
                    buttonText={t('actions.delete')}
                    isLoading={managerDeleteLoading}
                    error={t(managerDeleteError?.translation_error_key)}
                    loadingText={t('actions.sending')}
                    id={managerActiveId !== null && managerActiveId.uuid}
                    deleteInformation={t('users.delete.delete_account_title', {user: managerActiveId !== null ? (managerActiveId.last_name + " " + managerActiveId.first_name) : null})}
                    event={managerDeleteUser}
                    cancelText={t('actions.cancel')}
                />
                <Toolbar toggleRegisterView={managerViewRegisterToggle} searchLoad={managerSearchLoad}
                         searchUserResult={managerSearchUserResult} searchUser={managerRequestSearchUsers} t={t}/>
                <Box mt={3}>
                    {/* ユーザー一覧 */}
                    <UsersList
                        loginUserID={managerProfile.id}
                        managerLoading={managerLoading}
                        users={managerUsers}
                        managerViewDeleteToggle={managerViewUserDeleteToggle}
                        managerViewRegisterToggle={managerViewRegisterToggle}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
}

export default UserView;
