import React from 'react';
import {Field, Form} from 'react-final-form';
import {Button, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import FinalFormFieldIndependentError from "../../../../atoms/FinalFormFieldIndependentError"
import FinalFormSelect from "../../../../atoms/FinalFormSelect";
import FormForPostalCode from "../../../../atoms/FormForPostalCode";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {addressStoreValidation} from "../../../../../state/ducks/user/addresses/validation";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {addressForms} from "../../../../../state/ducks/user/addresses";
import states from "../../../../../state/utils/data/states";

/**
 * コンポーネントスタイル
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * 住所の新規・更新モーダル.
 *
 * @param className - 親のコンポーネントスタイル
 * @param {boolean} isOpen - モーダルの表示・非表示状態
 * @param {managerViewRegisterToggle} setClose - 非表示アクション
 * @param {Object} activeAddress - (編集モード) 選択した住所情報
 * @param {managerRegisterNewUser} addressRegister - 新規住所登録リクエスト
 * @param {boolean} RegisterLoading -ユーザー登録・編集のロード中状態
 * @param {Object} RegisterError - 登録・更新リクエストエラー
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const AddressModal = (
    {
        isOpen,
        setClose,
        activeAddress,
        addressRegister,
        addressEdit,
        registerLoading,
        registerError,
        profile,
        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // アプリケーションテーマ
    const globalStyle = globalTheme._default();
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={() => setClose({})}
               disableBackdropClick
               className={registerLoading ? globalStyle.loading : undefined}
        >
            <div className={globalStyle.modal} style={modalStyle}>
                <Typography variant="h2" style={{'paddingBottom': 30}}
                            id="simple-modal-title">{!empty(activeAddress) ? t('addresses.update.title') : t('addresses.create.title')}</Typography>
                <Form
                    onSubmit={async (e) => {
                        // リクエストを投げる前に郵便番号をまとめる
                        e.postal_code = e.postal_code1 + '-' + e.postal_code2
                        e = mergeWithDefaultForm(e, addressForms.createEditForm)
                        // 現在の選択した住所の存在があるか上で編集リクエストを投げる。
                        // いない場合、新規住所リクエストを投げる
                        if (empty(activeAddress)) {
                            // 新規住所リクエスト
                            if (!registerLoading) {
                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                    () => {
                                        return getAccessTokenSilently();
                                    },
                                );
                                addressRegister(e, access_token, profile?.account?.uuid);
                            }
                        } else {
                            // 更新住所リクエスト
                            if (!registerLoading) {
                                e.id = activeAddress.id;
                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                    () => {
                                        return getAccessTokenSilently();
                                    },
                                );
                                addressEdit(activeAddress, e, access_token, profile?.account?.uuid);
                            }
                        }

                    }}
                    // ここでフォームデータを妥当性確認し、キーを変換します。
                    validate={e => {
                        return translate(t, Validation({...e}, addressStoreValidation))
                    }}
                    // （編集モード）最初のバリュー
                    initialValues={{
                        postal_code1: !empty(activeAddress) ? activeAddress.postal_code.split('-')[0] : "",
                        postal_code2: !empty(activeAddress) ? activeAddress.postal_code.split('-')[1] : "",
                        state: !empty(activeAddress) ? activeAddress.state : "",
                        city: !empty(activeAddress) ? activeAddress.city : "",
                        address: !empty(activeAddress) ? activeAddress.address : ""
                    }}
                    // ここでは、フォームのレンダリングと制御を行います
                    // エラー処理やダブルクリック防止などはここで行います
                    render={({handleSubmit, form, submitting, pristine, invalid, values}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={3}>
                                    <Field
                                        name="postal_code1"
                                        fullWidth
                                        required
                                        size={"small"}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        value={!empty(activeAddress) && activeAddress.postal_code1}
                                        component={FormForPostalCode}
                                        type="tel"
                                        label={t('addresses.postal_code')}
                                    />
                                </Grid>
                                <Grid item style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Typography style={{position: 'relative', top: '40px'}}>
                                        {t('address.postal_code_hyphen')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        name="postal_code2"
                                        fullWidth
                                        size={"small"}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        value={!empty(activeAddress) && activeAddress.postal_code2}
                                        component={FormForPostalCode}
                                        type="tel"
                                        label="　"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{color: "#7E7E7E"}}>
                                        {t('address.postal_code_warning')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: 0, paddingLeft: 11, paddingRight: 16, paddingBottom: 0}}>
                                    <FinalFormFieldIndependentError name={"postal_code1"}/>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: 0, paddingLeft: 11, paddingRight: 16, paddingBottom: 0}}>
                                    <FinalFormFieldIndependentError name={"postal_code2"} />

                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="state"
                                        fullWidth
                                        required
                                        size={"small"}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        value={!empty(activeAddress) && activeAddress.state}
                                        component={FinalFormSelect}
                                        options={states['states']}
                                        label={t('addresses.state')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="city"
                                        fullWidth
                                        required
                                        size={"small"}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        value={!empty(activeAddress) && activeAddress.city}
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('addresses.city')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="address"
                                        fullWidth
                                        required
                                        size={"small"}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        value={!empty(activeAddress) && activeAddress.address}
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('addresses.address')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={globalStyle.error}>
                                        {t(registerError?.translation_error_key)}
                                    </Typography>
                                </Grid>
                                <Grid item container spacing={3} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            disabled={registerLoading}
                                            onClick={() => setClose({})}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={invalid || pristine || registerLoading}
                                        >
                                            {registerLoading ? t('actions.sending') : !empty(activeAddress) ? t('actions.change') : t('actions.add')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>)}
                />
            </div>

        </Modal>
    )
};

export default AddressModal;
