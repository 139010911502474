import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

// ユーザー一覧
const managerLoadingUserList = actions.managerLoadingUserList;
const managerFailureUserList = actions.managerFailureUserList;
const managerLoadUserList = actions.managerLoadUserList;
const managerLoadedUserList = actions.managerLoadedUserList;
const managerUserChangePage = actions.managerUserChangePage;

// ユーザー登録モダール
const managerViewRegisterToggle = actions.managerViewRegisterToggle;

// 新規ユーザーを登録
const managerRegisterNewUser = actions.managerRegisterNewUser;

// 新規ユーザー登録
const managerRequestSearchUsers = actions.managerRequestSearchUsers;

// ユーザー削除
const managerViewUserDeleteToggle = actions.managerViewUserDeleteToggle;
const managerDeleteUser = actions.managerDeleteUsers;
const managerDeleteUserLoading = actions.managerDeleteUserLoading;
const managerDeleteUserSuccess = actions.managerDeleteUserSuccess;
const managerDeleteUserFailure = actions.managerDeleteUserFailure;

// ユーザー更新
const managerUpdateUser = actions.managerUpdateUser;

const operations = {
    managerLoadingUserList,
    managerFailureUserList,
    managerLoadUserList,
    managerLoadedUserList,
    managerUserChangePage,

    managerViewRegisterToggle,
    managerRegisterNewUser,
    managerUpdateUser,
    managerRequestSearchUsers,

    managerViewUserDeleteToggle,
    managerDeleteUser,
    managerDeleteUserLoading,
    managerDeleteUserSuccess,
    managerDeleteUserFailure
};
export default operations;
