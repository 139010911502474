import React, {useEffect} from 'react';
import {Box, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import AddressesList from "./list/AddressesList";
import Toolbar from "./Toolbar";
import {loadAddressList} from "../../../../state/ducks/user/addresses/actions";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import {empty} from "../../../../state/utils/Common";
import AddressModal from "./modal/AddressModal";
import {ConfirmationModal} from "../../../atoms/ConfirmationModal";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {AlertModal} from "../../../atoms/AlertModal";

/**
 * /addresses コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
}}));
/**
 * 住所一覧画面コンポーネント
 *
 * @param {boolean} loading - 住所一覧のロード状態
 * @param {Object} activeProfile - ローグインユーザー
 * @param {Object} profile - ログインユーザープロフィール。
 * @param {*} updateAddress - 更新住所アクション
 * @param {Object} error - ページエラー
 * @param {boolean} isRegisterView = "" "Is the register modal open?" flag
 * @param {boolean} toggleRegisterView - 新規ユーザーアカウントのモダールトグル
 * @param {*} addressRegistrationLoad - 住所の登録ロード状態
 * @param {Object} registerError - モーダルのエラー
 * @param {*} registerAddress - 新規住所登録リクエスト
 * @param {boolean} searchLoad - ユーザー検索中のアクション
 * @param {Object} searchUserResult - user list from result
 * @param {boolean} reLoad - double load checker - ダブルクリック対策
 * @param {*} searchUser - ユーザー検索アクション
 * @param {Object} addresses - 現在のユーザー
 * @param {number} userList_CurrentPage - 現在のページ
 * @param {boolean} deleteView - 削除の表示・非表示状態
 * @param {boolean} deleteLoading - 削除中の確認
 * @param {Object} deleteError  - 削除エラー
 * @param {*} viewAddressDeleteToggle - 削除モーダルの表示・非表示状態
 * @param {*} deleteAddresses - 住所削除アクション
 * @param {*} changePage - ユーザー編集アクション
 * @param {*} t - translation
 * @returns {JSX.Element}
 * @constructor
 */
const AddressView = (
    {

        loading,
        activeProfile,
        profile,
        updateAddress,
        isRegisterView,
        toggleRegisterView,
        addressRegistrationLoad,
        registerError,
        registerAddress,
        searchLoad,
        searchUserResult,
        reLoad,
        searchUser,
        addresses,
        addressList_CurrentPage,
        deleteView,
        deleteLoading,
        deleteError,
        viewAddressDeleteToggle,
        deleteAddresses,
        changePage,
        t,
        deleteErrorModalView,
        confirmDeleteAddressDoNotDelete
    }) => {
    // auth0 アクショントークンを取得
    const {getAccessTokenSilently} = useAuth0();
    //アプリケーションテーマを取得
    const dispatch = useDispatch();
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                dispatch(loadAddressList(access_token, profile?.account?.uuid));
            })();
        }
    }, [dispatch, getAccessTokenSilently, profile, reLoad])
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('addresses.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2">{t('addresses.title')}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            data-testid="create-button"
                            onClick={() => toggleRegisterView()}
                        >
                            {t('addresses.new_account')}
                        </Button>
                    </Grid>
                </Grid>
                {/* 作成/編集 モーダル コンポーネント */}
                <AddressModal
                    isOpen={isRegisterView}
                    setClose={toggleRegisterView}
                    addressRegister={registerAddress}
                    registerLoading={addressRegistrationLoad}
                    activeAddress={activeProfile}
                    profile={profile}
                    registerError={registerError}
                    addressEdit={updateAddress}
                    t={t}
                />
                {/* 削除モーダルコンポーネント */}
                <ConfirmationModal
                    isOpen={deleteView}
                    isLoading={deleteLoading}
                    error={t(deleteError?.translation_error_key)}
                    profile={profile}
                    loadingText={t('actions.sending')}
                    setClose={viewAddressDeleteToggle}
                    confirmationTitle={t('addresses.delete.title')}
                    confirmationDescription={t('addresses.delete.description')}
                    buttonText={t('actions.delete')}
                    id={activeProfile !== null && activeProfile.id}
                    deleteInformation={t('addresses.delete.delete_account_title', {address: activeProfile !== null ? ( activeProfile.state + " " + activeProfile.city + " " + activeProfile.address) : null})}
                    event={deleteAddresses}
                    cancelText={t('actions.cancel')}
                />
                <AlertModal
                    loadingText={t('actions.sending')}
                    isLoading={false}
                    isOpen={deleteErrorModalView}
                    setClose={confirmDeleteAddressDoNotDelete}
                    buttonText={t('actions.ok')}
                    confirmationTitle={t('addresses.delete_failure.title')}
                    event={confirmDeleteAddressDoNotDelete}
                    deleteInformation={t(deleteError?.translation_error_key)}
                />
                <Toolbar toggleRegisterView={toggleRegisterView} searchLoad={searchLoad}
                         searchUserResult={searchUserResult} searchUser={searchUser}/>
                <Box mt={3}>
                    {/* 住所一覧 */}
                    <AddressesList
                        loading={loading}
                        addresses={addresses}
                        viewRegisterToggle={toggleRegisterView}
                        viewDeleteToggle={viewAddressDeleteToggle}
                        currentPage={addressList_CurrentPage}
                        changePage={changePage}
                        t={t}/>
                </Box>
            </Container>
        </Page>
    );
};

export default AddressView;
