import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

/**
 * 契約一覧取得
 * @type {(function(string, *=): (number|string))|*}
 */
const loadServiceContractList = actions.loadServiceContractList;
const loadingServiceContractList = actions.loadingServiceContractList;
const successServiceContractList = actions.successServiceContractList;
const failureServiceContractList = actions.failureServiceContractList;

/**
 * 契約詳細ページ
 * @type {(function(*=): {payload: {serviceContract}, type: string})|*}
 */
const extractServiceContractInformation = actions.extractServiceContractInformation;

/**
 * 連絡先のモーダル表示状態
 * @type {(function(): {type: string})|*}
 */
const toggleBilledToModal = actions.toggleBilledToModal;
const toggleSoldToModal = actions.toggleSoldToModal;

/**
 * 連絡先情報の更新
 * @type {(function(*=, *=, *=, *=, *=): {payload: {accountId, contactId, accessToken, serviceContractData, serviceContractId}, type: string})|*}
 */
const updateServiceContractContact = actions.updateServiceContractContact;
const updateServiceContractLoading = actions.updateServiceContractLoading;
const updateServiceContractSuccess = actions.updateServiceContractSuccess;
const updateServiceContractFailed = actions.updateServiceContractFailed;

/**
 * 契約詳細情報の取得
 * @type {(function(*=, *=, *=): {payload: {access_token, accountId, serviceContractId}, type: string})|*}
 */
const requestSingleServiceContract = actions.requestSingleServiceContract;
const loadSingleServiceContract = actions.loadSingleServiceContract;
const singleServiceContractSuccess = actions.singleServiceContractSuccess;
const singleServiceContractFailed = actions.singleServiceContractFailed;
/**
 * 連絡先情報のロード
 * @type {(function(*=, *=, *=, *=, *=): {payload: {accountId, contactId, accessToken, toggleEvent, serviceContractId}, type: string})|*}
 */
const loadServiceContractContact = actions.loadServiceContractContact
const loadServiceContractContactLoading = actions.loadServiceContractContactLoading
const loadServiceContractContactSuccess = actions.loadServiceContractContactSuccess
const loadServiceContractContactFailed = actions.loadServiceContractContactFailed


/***
 * 住所変更をモーダ機能
 * @type {(function(*=, *=, *=, *=, *=): {payload: {accountId, contactId, accessToken, toggleEvent, serviceContractId}, type: string})|*}
 */
const loadServiceContractContactAddress = actions.loadServiceContractContactAddress;
const loadServiceContractContactAddressLoading = actions.loadServiceContractContactAddressLoading;
const loadServiceContractContactAddressSuccess = actions.loadServiceContractContactAddressSuccess;
const loadServiceContractContactAddressFailed = actions.loadServiceContractContactAddressFailed;
const loadServiceContractContactAddressSoldToToggle = actions.loadServiceContractContactAddressSoldToToggle;
const loadServiceContractContactAddressBilledToToggle = actions.loadServiceContractContactAddressBilledToToggle;
const serviceContractContactAddressSelect = actions.serviceContractContactAddressSelect;

const updateServiceContractContactAddress = actions.updateServiceContractContactAddress;
const updateServiceContractContactAddressLoading = actions.updateServiceContractContactAddressLoading;
const updateServiceContractContactAddressSuccess = actions.updateServiceContractContactAddressSuccess;
const updateServiceContractContactAddressFailed = actions.updateServiceContractContactAddressFailed;


const operations = {
    loadServiceContractList,
    loadingServiceContractList,
    successServiceContractList,
    failureServiceContractList,

    extractServiceContractInformation,
    requestSingleServiceContract,
    loadSingleServiceContract,
    singleServiceContractSuccess,
    singleServiceContractFailed,

    toggleBilledToModal,
    toggleSoldToModal,
    updateServiceContractContact,
    updateServiceContractLoading,
    updateServiceContractSuccess,
    updateServiceContractFailed,
    updateServiceContractContactAddress,
    updateServiceContractContactAddressLoading,
    updateServiceContractContactAddressSuccess,
    updateServiceContractContactAddressFailed,

    loadServiceContractContact,
    loadServiceContractContactLoading,
    loadServiceContractContactSuccess,
    loadServiceContractContactFailed,

    loadServiceContractContactAddress,
    loadServiceContractContactAddressLoading,
    loadServiceContractContactAddressSuccess,
    loadServiceContractContactAddressFailed,
    loadServiceContractContactAddressSoldToToggle,
    loadServiceContractContactAddressBilledToToggle,
    serviceContractContactAddressSelect
}
export default operations;
