import {takeEvery, takeLeading} from 'redux-saga/effects';
import {addAddressList, deleteAddress, selectAddressList, updateAddress} from './sagas';
import {default as types} from "./types";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* fetchUsers() {
    yield takeLeading(types.REQUEST_ADDRESSES, selectAddressList);
    yield takeEvery(types.ADD_ADDRESS, addAddressList)
    yield takeEvery(types.UPDATE_ADDRESS, updateAddress)
    yield takeEvery(types.DELETE_ADDRESS, deleteAddress)
}
