/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（users）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const userWatcherSagas = Object.values(watchersSagas);
export {default as userListSelectors} from "./selectors";
export {default as userListOperations} from "./operations";
export {default as userListTypes} from "./types";
export {default as userForms} from "./forms"

export {userListState, default as userReducer} from './reducers';
