import {takeLeading} from "redux-saga/effects";
import {default as types} from "./types";
import {
    selectSingleServiceContract,
    selectSingleServiceContractContact,
    selectSingleServiceContractContactAddresses,
    selectServiceContracts,
    updateServiceContract,
    updateServiceContractContactAddress
} from "./sagas";


/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* fetchUsers() {
    yield takeLeading(types.REQUEST_SERVICE_CONTRACT, selectServiceContracts);
    yield takeLeading(types.UPDATE_SERVICE_CONTRACT_CONTACT_REQUEST, updateServiceContract);
    yield takeLeading(types.REQUEST_CONTACT_SERVICE_CONTRACT, selectSingleServiceContract);
    yield takeLeading(types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT, selectSingleServiceContractContact)
    yield takeLeading(types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS, selectSingleServiceContractContactAddresses)
    yield takeLeading(types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_REQUEST, updateServiceContractContactAddress)
}
