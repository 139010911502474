import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */
// 住所一覧取得
const loadingAddressList = actions.loadingAddressList;
const failureAddressList = actions.failureAddressList;
const loadAddressList = actions.loadAddressList;
const loadedAddressList = actions.loadedAddressList;

// モーダルの開閉
const toggleRegister = actions.viewRegisterToggle;
const viewAddressDeleteToggle = actions.viewAddressDeleteToggle;

// 新規住所登録リクエスト
const registerAddress = actions.registerNewAddress;
const addressRegistrationLoad = actions.addressRegistrationLoad;

// 新規住所更新リクエスト
const updateAddress = actions.updateAddress;

// ページネーション
const addressChangePage = actions.addressChangePage;

// 削除
const deleteAddresses = actions.deleteAddresses;
const deleteLoading = actions.deleteLoading;
const deleteAddressSuccess = actions.deleteAddressSuccess;
const deleteAddressFailure = actions.deleteAddressFailure;
const deleteAddressDoNotDelete = actions.deleteAddressDoNotDelete;
const confirmDeleteAddressDoNotDelete = actions.confirmDeleteAddressDoNotDelete;

const operations = {
    loadingAddressList,
    failureAddressList,
    loadAddressList,
    loadedAddressList,
    addressChangePage,

    toggleRegister,
    addressRegistrationLoad,
    registerAddress,
    updateAddress,

    viewAddressDeleteToggle,
    deleteLoading,
    deleteAddresses,
    deleteAddressSuccess,
    deleteAddressFailure,
    deleteAddressDoNotDelete,
    confirmDeleteAddressDoNotDelete
};
export default operations;
