import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {rootOperations} from "../../../state/ducks/root";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ErrorPage from "../../components/root/ErrorPage";
import SessionTimeoutUtil from "../../../state/utils/SessionTimeoutUtil";
import {empty} from "../../../state/utils/Common";

/**
 * 選択されたユーザーのRedux状態をコンポーネントに描画
 *
 * @param state
 * @returns {{isError, managerProfile, errorDetail: *}}
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        accessTokenFailureDialog: state.root.accessTokenFailureDialog
    }
}
/**
 * アクションやオペレーションをreduxからここに描く
 */
const mapEventToProps = {
    errorSend: rootOperations.errorSend
}

/**
 * レンダリングはしないが、auth0サインアウトプロセスを初期化するコンテナ
 * @param {Object} errorObject - エラー内容
 * @param {String} title - エラータイトル
 * @param {String} message - エラーメッセージ
 * @param {String} error_code - エラーコード
 * @param {*} t - 翻訳オブジェクト
 * @param {Object} managerProfile - ユーザープロファイル
 * @param {*} errorSend - エラー送信エベント
 * @param {null|SessionTimeoutUtil} sessionUtil - ログインセッション管理
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorLayout = (
    {
        errorObject,
        title,
        message,
        error_code,
        t,
        managerProfile,
        errorSend,
        sessionUtil,
    }
) => {
    const {
        logout
    } = useAuth0();
    useEffect(() => {
            if (sessionUtil instanceof SessionTimeoutUtil) {
                sessionUtil.disableUserTracking()
            }
            let _error = errorObject;
            if ('error' in errorObject) {
                let _error = errorObject.error
                if (_error instanceof Error) {
                    _error = JSON.parse(JSON.stringify(_error, Object.getOwnPropertyNames(_error)))
                }
            }
            (async () => {
                if (_error.translation_error_key) {
                    _error.translated_error = t(_error.translation_error_key)
                }
                // アクセストークンを送信しない。
                errorSend("", _error);
            })();
        }, [errorObject, errorSend, t, sessionUtil]
    )
    console.error((!empty(message) ? message : errorObject.toString()) + (!empty(error_code) ? ":" + error_code : ""))
    return (
        <ErrorPage
            t={t}
            error={errorObject}
            message={message}
            error_code={error_code}
            title={title}
            logout={logout}
        />
    )
}

export const ErrorLayoutContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withRouter,
    withTranslation('common'))(ErrorLayout)

export default ErrorLayoutContainer;
