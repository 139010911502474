import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Address from "../../../../components/user/serviceContracts/contact/Address";
import {serviceContractOperations} from "../../../../../state/ducks/user/service_contracts";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        addressEditLoading: state.serviceContract.addressEditLoading,
        contactAddresses: state.serviceContract.contactAddresses,
        activeAddress: state.serviceContract.activeAddress,
        addressEditUpdateLoading: state.serviceContract.addressEditUpdateLoading,
        addressEditError: state.serviceContract.addressEditError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadServiceContractContactAddress: serviceContractOperations.loadServiceContractContactAddress,
    serviceContractContactAddressSelect: serviceContractOperations.serviceContractContactAddressSelect,
    updateServiceContractContactAddress: serviceContractOperations.updateServiceContractContactAddress
}

const _AddressContainer = (
    {
        title,
        edit_title,
        current_address,
        addressEditLoading,
        editIsOpen,
        editModalToggleEvent,
        loadServiceContractContactAddress,
        contactAddresses,
        activeAddress,
        serviceContractContactAddressSelect,

        contactKey,
        contactData,
        serviceContractID,
        managerProfile,

        addressEditUpdateLoading,
        updateServiceContractContactAddress,
        addressEditError,

        t
    }) => {
    return (
        <Address
            t={t}
            title={title}
            loading={addressEditLoading}
            edit_title={edit_title}
            current_address={current_address}
            editIsOpen={editIsOpen}
            editModalToggleEvent={editModalToggleEvent}
            loadServiceContractContactAddress={loadServiceContractContactAddress}
            contactAddresses={contactAddresses}
            activeAddress={activeAddress}
            serviceContractContactAddressSelect={serviceContractContactAddressSelect}
            contactKey={contactKey}
            contactData={contactData}
            serviceContractID={serviceContractID}
            managerProfile={managerProfile}
            addressEditUpdateLoading={addressEditUpdateLoading}
            updateServiceContractContactAddress={updateServiceContractContactAddress}
            addressEditError={addressEditError}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * コンポーネントに追加する。
 */
const AddressContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_AddressContainer)

export default AddressContainer;
