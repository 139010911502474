import React from "react";
import UserLoginContainer from "../../containers/user/UserLoginContainer";

/**
 * 通常ユーザー側のサインアウトページ
 * @returns {JSX.Element}
 * @constructor
 */
const SignOutPage = () => {
    return (
        <div>
            <UserLoginContainer/>
        </div>
    )
}

export default SignOutPage;
