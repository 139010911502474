/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（managerUsers）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const rootWatcherSagas = Object.values(watchersSagas);
// export { default as mangerUserListSelectors } from "./selectors";
export {default as rootOperations} from "./operations";
export {default as rootTypes} from "./types";
export {rootState, default as mainReducer} from './reducers';
