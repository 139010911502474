import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているAddressesグループに束縛されています。
 * //////////////////////////////////////////
 */
export const addressListState = {
    isRegisterView: false,
    deleteView: false,
    deleteErrorModalView: false,
    activeProfile: {},

    deleteLoading: false,
    deleteError: {},
    registerLoading: false,
    registerError: {},

    loading: true,
    isError: false,
    addressList_CurrentPage: 0,

    // searchLoad: false,
    // searchUserResult: {},

    error_code: "",
    error: {},
    addressList: {},
};

export default function addressList(state = addressListState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // 住所一覧取得リクエスト
        // -----------------------------------------------
        case types.REQUEST_ADDRESSES_LOADING:
            return {
                ...state,
                loading: state.loading = true,
                isError: state.isError = false,
                isRegisterView: state.isRegisterView = false
            }
        case types.REQUEST_ADDRESSES_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                addressList: state.addressList = action.payload.data.addresses,
                isError: state.isError = false,
                error: state.error = {},
                error_code: ""
            }
        case types.REQUEST_ADDRESSES_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                error: state.error = action.payload.error,
                isError: state.isError = true,
            }
        // -----------------------------------------------
        // アカウント情報ページ変更
        // -----------------------------------------------
        case types.ADDRESS_CHANGE_PAGE:
            return {
                ...state,
                addressList_CurrentPage: state.addressList_CurrentPage = action.payload.page_no
            }
        // -----------------------------------------------
        // 住所登録
        // -----------------------------------------------
        case types.TOGGLE_ADDRESS_REGISTER:
            return {
                ...state,
                isRegisterView: !state.isRegisterView,
                activeProfile: state.activeProfile = action.payload.address_id,
                registerError: state.registerError = {},
            }
        case types.ADD_ADDRESS_LOADING:
            return {
                ...state,
                registerError: state.registerError = {},
                registerLoading: state.registerLoading = true
            }
        case types.ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                isError: state.isError = false,
                error: state.error = {},
                registerLoading: state.registerLoading = false,
                registerError: state.registerError = {},
                isRegisterView: state.isRegisterView = false,
                error_code: ""
            }
        case types.ADD_ADDRESS_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                isError: state.isError = false,
                registerLoading: state.registerLoading = false,
                registerError: state.registerError = action.payload.error,
            }
        // -----------------------------------------------
        // 住所削除
        // -----------------------------------------------
        case types.DELETE_ADDRESS_TOGGLE:
            return {
                ...state,
                deleteView: !state.deleteView,
                deleteError: state.deleteError = {},
                activeProfile: state.activeProfile = action.payload.accountId
            }
        case types.DELETE_ADDRESS_LOADING:
            return {
                ...state,
                deleteLoading: state.deleteLoading = true,
                deleteError: state.deleteError = {},
            }
        case types.DELETE_ADDRESS_SUCCESS:
            return {
                ...state,
                deleteLoading: state.deleteLoading = false,
                deleteError: state.deleteError = {},
                activeProfile: state.activeProfile = {},
                deleteView: state.deleteView = false
            }
        case types.DELETE_ADDRESS_FAILURE:
            return {
                ...state,
                deleteError: state.deleteError = action.payload.error,
                deleteLoading: state.deleteLoading = false,
            }
        case types.DELETE_DO_NOT_DELETE:
            return {
                ...state,
                deleteError: state.deleteError = action.payload.error,
                deleteLoading: state.deleteLoading = false,
                deleteView: state.deleteView = false,
                deleteErrorModalView: state.deleteErrorModalView = true
            }
        case types.DELETE_ADDRESS_DO_NOT_DELETE_CONFIRM:
            return {
                ...state,
                deleteError: state.deleteError = {},
                deleteLoading: state.deleteLoading = false,
                deleteView: state.deleteView = false,
                deleteErrorModalView: state.deleteErrorModalView = false,
            }
        default:
            return state;
    }
}
