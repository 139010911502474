import React from 'react';
import {Box, Breadcrumbs, Container, Grid, Typography} from '@material-ui/core';
import Page from "../Page";
import AccountUserList from "./list/AccountUserList";
import {Link} from "react-router-dom";
import globalTheme from "../../../theme/globalTheme";

/**
 * アカウントのユーザー一覧
 * @param {Object} classes
 * @param {boolean} managerAccountUsersLoading - ロード中かどうか
 * @param {Object} managerAccountActiveUsers - アカウント ユーザーリスト データ
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountUserView = (
    {
        classes,
        managerAccountUsersLoading,
        managerAccountActiveUsers,
        t
    }) => {
    const globalClass = globalTheme._default();
    return (
        <Page
            className={classes.root}
            title={t('accounts.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2">{t('accounts.title')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs aria-label="breadcrumb" separator={">"} style={{fontSize: 11}}>
                            <Link color="inherit" className={globalClass.a} style={{textDecoration: "none"}}
                                  to="/manager/accounts/">
                                {t('accounts.title')}
                            </Link>
                            <Typography variant="body1">{t('accounts.users.user_list')}</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <AccountUserList
                        managerAccountActiveUsers={managerAccountActiveUsers}
                        managerAccountUsersLoading={managerAccountUsersLoading}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ManagerAccountUserView;
