/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

/***
 * 新規アカウント追加・アカウント編集フォーム
 * @type {{last_name, first_name, email}}
 */
const createEditForm = {
    last_name: null,
    first_name: null,
    email:null
}

const forms = {
    createEditForm
}

export default forms;
