import ApiUtils from "../../utils/ApiUtils";
import {CALL_PROFILE, ERROR_CATCH_API} from './constants'
import profileSchema from "./schema/profile.json"
/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * アプリケーションレベルのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

require('dotenv').config()

/**
 * APIからのプロファイル取得
 * @param {string} user - Auth0のユーザーID
 * @param {string} accessToken - auth0 アクセストークン
 * @returns {AxiosPromise}
 */
export function callManagerProfileAPI(user, accessToken) {
    return new ApiUtils(accessToken, null, process.env.REACT_APP_MANAGER_PROFILE_DOMAIN).get(
        encodeURI(CALL_PROFILE + user),
        null,
        profileSchema
    );
}

/***
 * クライアントエラー内容の送信
 * @param accessToken - アクセストークン
 * @param error - エラー内容
 * @returns {AxiosPromise}
 */
export function sendErrorReportAPI(accessToken, error) {
    return new ApiUtils(accessToken, null, process.env.REACT_APP_MANAGER_PROFILE_DOMAIN, true).post(
       ERROR_CATCH_API,
       error
    )
}

