import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */
// ユーザー一覧取得
const loadingUserList = actions.loadingUserList;
const failureUserList = actions.failureUserList;
const loadUserList = actions.loadUserList;
const loadedUserList = actions.loadedUserList;

// モーダルの開閉
const toggleRegister = actions.viewRegisterToggle;
const viewUserDeleteToggle = actions.viewUserDeleteToggle;

// 新規ユーザー登録リクエスト
const registerUser = actions.registerNewUser;
const userRegistrationLoad = actions.userRegistrationLoad;

// 新規ユーザー更新リクエスト
const updateUser = actions.updateUser;

// 検索リクエスト
const requestSearchUsers = actions.requestSearchUsers;

// ページネーション
const userChangePage = actions.userChangePage;

// 削除
const deleteUsers = actions.deleteUsers;
const deleteLoading = actions.deleteLoading;
const deleteUserSuccess = actions.deleteUserSuccess;
const deleteUserFailure = actions.deleteUserFailure;

const operations = {
    loadingUserList,
    failureUserList,
    loadUserList,
    loadedUserList,
    userChangePage,

    toggleRegister,
    userRegistrationLoad,
    registerUser,
    updateUser,
    requestSearchUsers,

    viewUserDeleteToggle,
    deleteLoading,
    deleteUsers,
    deleteUserSuccess,
    deleteUserFailure
};
export default operations;
