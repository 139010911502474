import {Box, Grid, makeStyles, Menu, MenuItem, Paper, Typography} from "@material-ui/core";
import LogOutButton from "./Logout";
import React from "react";
import {empty} from "../../state/utils/Common";

/**
 * コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    logoutButton: {
        border: "1px #CCC solid",
        transition: "all 0.3s",
        height: 48,
        marginTop: 11,
        marginBottom: 11,
        boxShadow: "none",
        "&:hover": {
            background: "#F2F2F2",
        },
    }
}));

/**
 * ログアウトボタンの機能
 *
 * @param {string} companyName - 会社名
 * @param {string} userName - ユーザー名
 * @param {string} signOutUri - ログアウト用URL
 * @param {*} t - 翻訳対象
 * @param {*} rest - 追加属性
 * @returns {JSX.Element}
 * @constructor
 */
const LogoutMenu = ({companyName, userName, signOutUri, t}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    /**
     * Mobile Nav on open handle
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    /**
     * Mobile Nav on close handle
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Paper elevation={3} className={classes.logoutButton}>
                <Box onClick={handleClick} style={{cursor: "pointer", padding: 6}}>
                    {!empty(companyName) &&
                    <Typography variant={"subtitle2"}>
                        {companyName}
                    </Typography>
                    }
                    <Typography variant={"subtitle2"}>
                        {userName} {t('users.honorary_name')}
                    </Typography>
                </Box>
                <Menu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    transformOrigin={{vertical: "top", horizontal: "center"}}
                    getContentAnchorEl={null}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem style={{backgroundColor: 'transparent'}}>
                        <Grid container>
                            {!empty(companyName) &&
                            <Grid item xs={12}>
                                <Box paddingY={.5}>
                                    <Typography variant={"subtitle2"}>
                                        {companyName}
                                    </Typography>
                                </Box>
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <Box paddingY={.5}>
                                    <Typography variant={"subtitle2"}>
                                        {userName} {t('users.honorary_name')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box paddingY={1}>
                                    <LogOutButton signOutUri={signOutUri} t={t}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </MenuItem>
                </Menu>
            </Paper>
        </div>

    )
}

export default LogoutMenu;
