import React from 'react';
import {CircularProgress, Grid} from "@material-ui/core";

/**
 * ローディング・コンポーネント
 *
 * @returns {JSX.Element}
 * @constructor
 */
const FullScreenLoading = () => {
    return (
        <Grid
            style={{height: "100vh", alignContent: "center"}}
            container
            spacing={0}
            align="center"
            justify="center"
        >
            <Grid item>
                <CircularProgress/>
            </Grid>
        </Grid>
    );
};

export default FullScreenLoading;
