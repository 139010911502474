import {Box, Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";
import globalTheme from "../../../../theme/globalTheme";

export const ServiceContractRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.service_contracts[props.index].service_account_id} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #F2F2F2 solid"
        }}>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.service_contracts[props.index].service_account_id}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.service_contracts[props.index].service_account_name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.service_contracts[props.index].sold_to_contact?.last_name} {props.data.service_contracts[props.index].sold_to_contact?.first_name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.service_contracts[props.index].bill_to_contact?.last_name} {props.data.service_contracts[props.index].bill_to_contact?.first_name}
                </Typography>
            </Grid>
            <Grid item xs={1} style={{marginTop: -8, marginLeft: -40}}>
                <Box
                    justifyContent="flex-end"
                    display="flex"
                >
                    <Link
                        to={'/contacts/' + props.data.service_contracts[props.index].uuid}
                        data-testid="show-more-button">
                        <Button variant="contained" color="secondary">
                            {props.data.t('actions.detail')}
                        </Button>
                    </Link>
                </Box>
            </Grid>
        </Grid>
    )
}
