import {managerAccountListOperations} from "../../../../state/ducks/manager/managerAccounts";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ManagerAccountView from "../../../components/manager/accounts/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        managerProfile: state.root.managerProfile,

        currentPage: state.managerAccount.currentPage,
        accounts: state.managerAccount.managerAccountList,

        managerAccountIsRegisterView: state.managerAccount.managerAccountIsRegisterView,
        managerAccountIsEditView: state.managerAccount.managerAccountIsEditView,
        managerAccountRegisterError: state.managerAccount.managerAccountRegisterError,
        managerAccountRegisterLoading: state.managerAccount.managerAccountRegisterLoading,

        managerLoading: state.managerAccount.managerLoading,
        managerSearchLoad: state.managerAccount.managerSearchLoad,
        searchAccountResult: state.managerAccount.managerSearchAccountResult,
        deleteView: state.managerAccount.managerDeleteView,
        managerDeleteLoading: state.managerAccount.managerDeleteLoading,
        activeId: state.managerAccount.managerActiveId,
        managerError: state.managerAccount.managerError,
        managerDeleteError: state.managerAccount.managerDeleteError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    toggleRegisterView: managerAccountListOperations.managerViewRegisterToggle,
    registerAccount: managerAccountListOperations.managerRegisterNewAccount,
    searchAccount: managerAccountListOperations.managerRequestSearchAccounts,
    toggleDelete: managerAccountListOperations.managerViewDeleteToggle,
    managerViewEditToggle: managerAccountListOperations.managerViewEditToggle,
    managerEditAccount: managerAccountListOperations.managerEditAccount,
    managerDeleteAccount: managerAccountListOperations.managerDeleteAccount,
    managerChangePageAccountList: managerAccountListOperations.managerChangePageAccountList,
    managerLoadAccountList: managerAccountListOperations.managerLoadAccountList,
    managerViewRegisterToggleInitSFAccountLoad: managerAccountListOperations.managerViewRegisterToggleInitSFAccountLoad
}


/**
 * Redux
 * i18next Translations
 * を
 * ManagerAccountView コンポーネントにマージする。
 */

const ManagerAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(ManagerAccountView)

export default ManagerAccountContainer;
