import React from 'react';
import {Box, Breadcrumbs, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../manager/Page";
import Loading from "../../../atoms/Loading";
import {empty} from "../../../../state/utils/Common";
import AddressContainer from "../../../containers/user/serviceContracts/contacts/AddressContainer";
import {Link} from "react-router-dom";
import globalTheme from "../../../theme/globalTheme";
import ContactContainer from "../../../containers/user/serviceContracts/contacts/ContactContainer";
import {useHistory} from "react-router";

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1)
    }
}));

/**
 * 利用契約の詳細ページコンポーネント
 * /service_contactの利用契約を選択したレコードからの画面
 *
 * @param {Object} managerProfile - ローグインしたユーザー情報
 * @param {Object} activeServiceContract - 選択した利用契約
 * @param {*} toggleSoldToModal - アカウント担当者の編集モーダルの表示・非表示アクション
 * @param {*} toggleBilledToModal - 請求担当者の編集モーダルの表示・非表示アクション
 * @param {boolean} contactBilledToEditModal - 請求担当者の編集モーダルの表示・非表示状態
 * @param {boolean} contactSoldToEditModal - アカウント担当者の編集モーダルの表示・非表示状態
 * @param {boolean} addressBilledToEditModal - (請求)担当者の住所編集モーダルの表示・非表示状態
 * @param {*} addressBilledToEditModalEvent - 請求担当者の編集アクション
 * @param {*} addressSoldToEditModalEvent - アカウント担当者の編集アクション
 * @param {boolean} addressSoldToEditModal - (aアカウント)担当者の住所編集モーダルの表示・非表示状態
 * @param {boolean} contactLoading - コンポーネントのロード状態
 * @param {Object} activeServiceContractError - コンポーネントのエラー
 * @param t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ContactView = (
    {
        managerProfile,

        activeServiceContract,
        toggleSoldToModal,
        toggleBilledToModal,
        contactBilledToEditModal,
        contactSoldToEditModal,
        addressBilledToEditModal,

        addressBilledToEditModalEvent,
        addressSoldToEditModalEvent,

        addressSoldToEditModal,
        contactLoading,
        activeServiceContractError,
        t
    }) => {
    // アプリケーションスタイル
    const classes = useStyles();
    const globalClass = globalTheme._default();
    // URL用ライブラリのロード
    const history = useHistory();
    // 連絡先情報がない場合は、serviceContractsページにリダイレクトされます。
    if (
        empty(activeServiceContract)
        && contactLoading === false
        && !empty(activeServiceContractError)
    ) {
        history.push('/service_contracts')
        return (<div> リダイレクト中</div>)
    }
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.detail.title')}
        >
            {contactLoading === false ?
                <Container maxWidth={false}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h2">{t('service_contracts.title')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Breadcrumbs aria-label="breadcrumb" separator={">"} style={{fontSize: 11}}>
                                <Link color="inherit" className={globalClass.a} style={{textDecoration: "none"}}
                                      to="/service_contracts/">
                                    {t('service_contracts.title')}
                                </Link>
                                <Typography variant="body1">{t('service_contracts.contact_breadcrumb')}</Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <ContactContainer
                            title={t('service_contracts.detail.sold_to_title')}
                            editTitle={t('service_contracts.detail.sold_to_title_edit')}
                            toggleEditContact={toggleSoldToModal}
                            contactEditModal={contactSoldToEditModal}
                            contactKey="sold_to_contact"
                            contactData={activeServiceContract.sold_to_contact}
                        />
                    </Box>
                    <Box mt={3}>
                        <ContactContainer
                            title={t('service_contracts.detail.billed_to_title')}
                            editTitle={t('service_contracts.detail.billed_to_title_edit')}
                            toggleEditContact={toggleBilledToModal}
                            contactEditModal={contactBilledToEditModal}
                            contactKey="bill_to_contact"
                            contactData={activeServiceContract.bill_to_contact}
                        />
                    </Box>
                    <Box mt={3}>
                        {
                            !empty(activeServiceContract?.sold_to_contact) &&
                            activeServiceContract?.sold_to_contact?.id !== activeServiceContract?.bill_to_contact?.id &&
                        <AddressContainer
                            current_address={activeServiceContract?.sold_to_contact?.address}
                            title={t('service_contracts.detail.address.sold_to_address')}
                            edit_title={t('service_contracts.detail.address.sold_to_address_edit')}
                            editIsOpen={addressSoldToEditModal}
                            editModalToggleEvent={addressSoldToEditModalEvent}
                            contactKey="sold_to_contact"
                            contactData={activeServiceContract.sold_to_contact}
                            serviceContractID={activeServiceContract.uuid}
                            managerProfile={managerProfile}
                            t={t}
                        />
                        }
                    </Box>
                    <Box mt={3}>
                        {!empty(activeServiceContract?.bill_to_contact) &&
                        <AddressContainer
                            current_address={activeServiceContract?.bill_to_contact?.address}
                            title={t('service_contracts.detail.address.billed_to_address')}
                            edit_title={t('service_contracts.detail.address.billed_to_address_edit')}
                            editIsOpen={addressBilledToEditModal}
                            editModalToggleEvent={addressBilledToEditModalEvent}
                            contactKey="billed_to_contact"
                            contactData={activeServiceContract.bill_to_contact}
                            serviceContractID={activeServiceContract.uuid}
                            managerProfile={managerProfile}
                            t={t}
                        />
                        }
                    </Box>
                </Container>
                :
                <Container maxWidth={false}>
                    <Loading/>
                </Container>
            }
        </Page>
    );
}

export default ContactView;
