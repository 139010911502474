/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（managerAccountsなど）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const managerAccountWatcherSagas = Object.values(watchersSagas);
export {default as managerAccountSelectors} from "./selectors";
export {default as managerAccountListOperations} from "./operations";
export {default as managerAccountListTypes} from "./types";
export {default as managerAccountForms} from "./forms"

export {managerAccountListState, default as managerAccountReducer} from './reducers';
