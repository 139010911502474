import React from 'react';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../state/utils/Common";
import {Chip, FormLabel, Typography} from "@material-ui/core";
import globalTheme from "../theme/globalTheme";
import Auth0Utils from "../../state/utils/Auth0Utils";
import debounce from "lodash/debounce";

/**
 * Auto Suggest Input Box - フォーム用のみ
 * 入力フィールドに結果を提案する。
 *
 * @param {boolean} loading - "入力欄が読み込まれていますか？"  フラグ
 * @param {*} searchEvent - 検索のイベント
 * @param {Object} result - 検索結果
 * @param {string} labelResult - ロップダウンに表示する選択オブジェクト
 * @param {*} additionalEvent - ??
 * @param {boolean} disabled - "このフィールドは無効ですか？" フラグ
 * @param {number} width - 入力フィールドの幅
 * @param {string|null} secondaryKey - オブジェクト対象の二次キー
 * @param {string} placeholder - プレースホルダーテキスト
 * @param {string} label - フォームラベル
 * @param requiresEnter - trueの場合検索するにはEnterキーを押せないと何も変わらない。
 * @param {string|null} description - 入力欄の説明
 * @param {null|Object} additionalData - 押すための追加情報
 * @param {*} customSearchEvent - カスタム検索イベント
 * @param customOptionSelectedEvent - カスタム選択した後イベント
 * @param {*} customSelectEvent - カスタム選択イベント
 * @param {*} selectValue - カスタム選択バリュー
 * @param className - 親からのクラス名
 * @param {boolean} loadingOnDisable - 入力項目をロードポインターに変更。
 * @param {*} customClearEvent - クリアバリューイベント
 * @param required - "このフィールドは必須ですか？"フラグ
 * @param autoHighlight - 自動選択機能
 * @param rest - その他の変数
 * @returns {JSX.Element}
 * @constructor
 */
export const AutoSuggestBoxStates = (
    {
        loading,
        searchEvent,
        result,
        labelResult,
        additionalEvent,
        disabled = false,
        width = 300,
        secondaryKey = null,
        placeholder,
        label,
        requiresEnter = false,
        description,
        additionalData = null,
        customSearchEvent = null,
        customOptionSelectedEvent = null,
        customSelectEvent = null,
        selectValue,
        className,
        loadingOnDisable,
        customClearEvent = null,
        required = false,
        autoHighlight = true,
        ...rest
    }) => {
    const [open, setOpen] = React.useState(false);
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();
    // スタイル
    const formStyle = globalTheme.defaultFormStyle();
    const loadOnDisable = loadingOnDisable && disabled ? formStyle.loadingOnDisable : undefined
    var resultArray = Object.keys(result).map(function (key) {

        // Number()を使ってキーを数値型に変換する
        // obj[key]を使用してキーの値を取得する
        return [Number(key), result[key]];
    });
    return (
        <div>
            <FormLabel className={loadOnDisable}>
                {label}
                {required &&
                <Chip label={"必須"} size="small" className={formStyle.chip}/>
                }
            </FormLabel>
            {description &&
            <Typography variant={"body1"} style={{paddingTop: 10, paddingBottom: 10}}>
                {description}
            </Typography>
            }
            <Autocomplete
                fullWidth={true}
                className={[formStyle.textfield, loadOnDisable].join(" ")}
                size={"small"}
                open={open}
                autoHighlight={autoHighlight}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                disabled={disabled}
                getOptionSelected={customOptionSelectedEvent == null ? (option, value) => {
                    return option[labelResult] === value[labelResult]
                } : customOptionSelectedEvent}
                onChange={(event, value, reason, details) => {
                    if (reason === 'select-option') {
                        customSelectEvent(value)
                    } else if (reason === 'clear') {
                        customClearEvent()
                    }
                }}
                getOptionLabel={customSearchEvent === null ? (option) => {
                    if (empty(option[1])) {
                        return option[labelResult]
                    }
                    if (secondaryKey !== null) {
                        return option[1][secondaryKey][labelResult]
                    }
                    return option[1][labelResult]
                } : customSearchEvent}
                onKeyDown={(event => {
                    if(event.key === "Enter" && requiresEnter && !empty(searchEvent)) {
                        (async () => {
                            const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                () => {
                                    return getAccessTokenSilently();
                                },
                            );
                            searchEvent(event.target.value, access_token, additionalData);
                        })()
                    }
                })}
                onInputChange={debounce((e, value) => {
                    if (!empty(searchEvent) && !requiresEnter) {
                        if (e.type !== 'click') {
                            (async () => {
                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                    () => {
                                        return getAccessTokenSilently();
                                    },
                                );
                                searchEvent(value, access_token, additionalData);
                            })()
                        }
                    }
                }, process.env.REACT_APP_SEARCH_TIMEOUT_MILLISECONDS)}
                options={resultArray}
                value={selectValue()}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...rest}
                        className={className}
                        fullWidth={true}
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
}
