/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

/**
 * 連絡先編集
 * @type {{mobile_phone: null, work_phone: null, last_name: null, department: null, first_name: null, email: null}}
 */
const contactEditForm = {
    last_name:null,
    first_name:null,
    work_phone:null,
    mobile_phone:null,
    email:null,
    department:null
}

/**
 * 連絡先住所変更フォーム
 * @type {{id: null}}
 */
const addressEditForm = {
    id: null
}

export const forms = {
    contactEditForm,
    addressEditForm
}

export default forms
