import types from "./types";
/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// アカウント一覧
//-------------------------------------------------

/**
 * ロードアカウント一覧リクエスト
 *
 * @param {string} access_token
 */
export function managerLoadAccountList(access_token) {
    return {
        type: types.MANAGER_REQUEST_ACCOUNTS,
        payload: {
            access_token: access_token
        }
    };
}

/**
 * アカウント一覧取得リクエスト(ロード中)
 */
export function managerLoadingAccountList() {
    return {
        type: types.MANAGER_REQUEST_ACCOUNTS_LOADING
    }
}

/**
 * アカウント一覧取得リクエスト(成功)
 * @param {Object} accounts - 取得アカウント
 */
export function managerLoadedAccountList(accounts) {
    return {
        type: types.MANAGER_REQUEST_ACCOUNTS_SUCCESS,
        payload: accounts
    }
}

/**
 * アカウント一覧取得リクエスト(失敗)
 *
 * @param {Object} error - エラーオブジェクト
 */
export function managerFailureAccountList(error) {
    return {
        type: types.MANAGER_REQUEST_ACCOUNTS_FAILURE,
        payload: {
            managerError: error
        }
    }
}

/**
 * ページ変更
 *
 * @param {number|string} pageNo
 */
export function managerChangePageAccountList(pageNo) {
    return {
        type: types.MANAGER_CHANGE_ACCOUNT_PAGE,
        payload: {
            pageNo: pageNo
        }
    }
}

//-------------------------------------------------
// 検索機能
//-------------------------------------------------

/**
 * アカウント検索
 *
 * @param {string} searchName - キーワード
 * @param {string} access_token - アクセストークン
 */
export function managerRequestSearchAccounts(searchName, access_token) {
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_REQUEST,
        payload: {
            data: {
                keyword: searchName
            },
            access_token: access_token
        }
    };
}

/**
 * アカウント検索(ロード中)
 */
export function managerLoadingSearchAccounts() {
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_LOADING,
    }
}

/**
 * アカウント検索(成功)
 * @param {Object} accounts - アカウント一覧
 */
export function managerSuccessSearchAccounts(accounts) {
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_SUCCESS,
        payload: {
            accountResult: accounts
        }
    }
}

/**
 * アカウント検索(失敗)
 * @param {Object} exception - エラー内容
 */
export function managerFailureSearchAccounts(exception) {
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_FAILURE,
        payload: {
            managerError: exception
        }
    }
}


//-------------------------------------------------
// アカウント登録
//-------------------------------------------------
/**
 * アカウント登録・変更モーダル表示・非表示
 */
export function managerViewRegisterToggle() {
    return {
        type: types.MANAGER_ACCOUNT_TOGGLE_REGISTER,
    }
}

/**
 * アカウント登録・変更モーダル表示・非表示と全体アカウントを取得する
 * @param {string} access_token アクセストークン
 * @returns {{payload: {access_token}, type: string}}
 */
export function managerViewRegisterToggleInitSFAccountLoad(access_token) {
    return {
        type: types.MANAGER_ACCOUNT_TOGGLE_REGISTER_INIT_SF_ACC_LOAD,
        payload: {
            access_token: access_token
        }
    }
}

/**
 * SFアカウント検索リクエスト
 *
 * @param {string} account_name - キーワード
 * @param {string} access_token - アクセストークン
 */
export function managerSearchSalesForceAccount(account_name, access_token) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_REQUEST,
        payload: {
            account_name: {keyword: account_name},
            access_token: access_token
        }
    }
}

/**
 * 選択したアカウントをクリアする
 */
export function managerSelectedSalesForceAccountClear() {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_CLEAR
    }
}

/**
 * SFアカウント検索リクエスト(成功)
 * @param {Object} salesForceAccounts - SFアカウント一覧
 */
export function managerSelectedSalesForceAccountSuccess(salesForceAccounts) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_SUCCESS,
        payload: {
            salesForceAccounts: salesForceAccounts
        }
    }
}

/**
 * SFアカウント検索成功リクエスト(失敗)
 * @param {Object} e - エラー内容
 */

export function managerSelectedSalesForceAccountFailure(e) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_FAILURE,
        payload: e
    }
}

/**
 * SFアカウント検索（ロード中）
 *
 */
export function managerLoadingSalesForceAccount() {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_LOADING
    }
}

/**
 * SFアカウント選択
 * @param {Object} salesForceAccount - 選択したSFアカウント
 */
export function managerSelectedSalesForceAccount(salesForceAccount) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_SELECT,
        payload: {
            salesForceAccount: salesForceAccount
        }
    }
}

/**
 * SFアカウントのユーザーをロードする
 *
 * @param salesForceAccount - 選択したSFアカウント
 * @param access_token - auth0 アクセストークン
 */
export function managerSelectedSalesForceAccount_LoadUsers(salesForceAccount, access_token) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_USER_SELECT,
        payload: {
            salesForceAccount: salesForceAccount,
            access_token: access_token,
        }
    }
}

/**
 * SFユーザー検索リクエスト
 * @param {string} user_name - キーワード
 * @param {string} access_token - auth0 アクセストークン
 * @param {Object} account - SFアカウント
 */
export function managerSearchSalesForceUser(user_name, access_token, account) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_USER_REQUEST,
        payload: {
            user_name: {keyword: user_name},
            access_token: access_token,
            account: account
        }
    }
}

/**
 * SFユーザーを選択する
 *
 * @param {Object} salesForceUsers - SFユーザーの検索結果
 */
export function managerSelectedSalesForceUserSuccess(salesForceUsers) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_USER_SUCCESS,
        payload: {
            salesForceUsers: salesForceUsers
        }
    }
}

/**
 * 選択したSFユーザーをクリアする。
 */
export function managerSelectedSalesForceUserClear() {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_USER_CLEAR
    }
}

/**
 * SFユーザー検索の失敗
 *
 * @param {Object} e - エラー内容
 */
export function managerSelectedSalesForceUserFailure(e) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_USER_FAILURE,
        payload: e
    }
}

/**
 * SFユーザーリクエスト（ロード中）
 */
export function managerLoadingSalesForceUser() {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_USER_LOADING
    }
}

/**
 *
 * SFユーザーを選択する
 *
 * @param {Object} salesForceUser - 選択したSFユーザー
 */
export function managerSelectedSalesForceUser(salesForceUser) {
    return {
        type: types.MANAGER_SEARCH_SALESFORCE_USER_SELECT,
        payload: {
            salesForceUser: salesForceUser
        }
    }
}

/**
 * 新規アカウント開設開始
 *
 * @param {Object} account - アカウント情報
 * @param {Object} account.sf_account - SFアカウント
 * @param {Object} account.sf_account_user - SFユーザー
 * @param {string} account.notes - アカウント備考
 * @param {string} account.sf_account.sf_id = SFアカウントID
 * @param {string} account.sf_account_user.email = SFアカウントメールアドレス
 * @param {string} account.sf_account_user.id = SFユーザーID
 * @param {string} access_token - auth0 アクセストークン
 */
export function managerRegisterNewAccount(account, access_token) {
    return {
        type: types.MANAGER_ADD_ACCOUNT,
        payload: {
            data: {
                sf_id: account.sf_account.sf_id,
                note: account.notes,
                sf_contact_id: account.sf_account_user.id
            },
            access_token: access_token
        }
    }
}

/**
 * 新規アカウント登録リクエスト（ロード中）
 */
export function managerRegisterNewAccountLoading() {
    return {
        type: types.MANAGER_ADD_ACCOUNT_LOADING,
    }
}

/**
 * 新規アカウント登録リクエスト（成功）
 */
export function managerRegisterNewAccountSuccess() {
    return {
        type: types.MANAGER_ADD_ACCOUNT_SUCCESS
    }
}

/**
 * 新規アカウント登録リクエスト（失敗）
 * @param {Object} e - エラー内容
 */
export function managerRegisterNewAccountFailure(e) {
    return {
        type: types.MANAGER_ADD_ACCOUNT_FAILURE,
        payload: {
            managerError: e
        }
    }
}

//-------------------------------------------------
// アカウント編集
//-------------------------------------------------
/**
 * アカウント編集モーダル表示・非表示
 * @param {Object} account - 選択したアカウント
 */
export function managerViewEditToggle(account) {
    return {
        type: types.MANAGER_ACCOUNT_TOGGLE_EDIT,
        payload: {
            account: account
        }
    }
}

/**
 * アカウント編集リクエスト
 * @param {Object} account - アカウントID
 * @param {Object} new_info - 編集した情報
 * @param {string} access_token - アクセストークン
 */
export function managerEditAccount(account, new_info, access_token) {
    return {
        type: types.MANAGER_EDIT_ACCOUNT_REQUEST,
        payload: {
            account: account,
            updateInformation: new_info,
            access_token: access_token
        }
    }
}

/**
 * アカウント編集リクエスト（ロード中）
 */
export function managerAccountEditLoad() {
    return {
        type: types.MANAGER_EDIT_ACCOUNT_LOADING
    }
}

/**
 * アカウント編集リクエスト（成功）
 */
export function managerEditAccountSuccess() {
    return {
        type: types.MANAGER_EDIT_ACCOUNT_SUCCESS
    }
}

/**
 * アカウント編集リクエスト（失敗）
 * @param {Object} e - エラー内容
 */
export function managerEditAccountFailure(e) {
    return {
        type: types.MANAGER_EDIT_ACCOUNT_FAILURE,
        payload: e
    }
}


//-------------------------------------------------
// アカウント削除
//-------------------------------------------------
/**
 * アカウント削除モーダル表示・非表示
 * @param {Object} accountId - アカウントID
 */
export function managerViewDeleteToggle(accountId) {
    return {
        type: types.MANAGER_TOGGLE_DELETE,
        payload: {
            accountId: accountId
        }
    }
}

/**
 * 削除リクエスト
 * @param {Object} account - アカウントID
 * @param {string} access_token - auth0 アクセストークン
 */
export function managerDeleteAccount(account, access_token) {
    return {
        type: types.MANAGER_DELETE_ACCOUNT_REQUEST,
        payload: {
            account: account,
            access_token: access_token
        }
    }
}

/***
 * アカウントのロード中状態
 * @returns {{type: string}}
 */
export function managerDeleteAccountLoading() {
    return {
        type: types.MANAGER_DELETE_ACCOUNT_LOADING
    }
}

/**
 * アカウント削除リクエスト（成功）
 */
export function managerDeleteAccountSuccess() {
    return {
        type: types.MANAGER_DELETE_ACCOUNT_SUCCESS
    }
}

/**
 * アカウント削除リクエスト（失敗）
 *
 * @param {Object} e - エラー内容
 */
export function managerDeleteAccountFailure(e) {
    return {
        type: types.MANAGER_DELETE_ACCOUNT_FAILURE,
        payload: {
            error: e
        }
    }
}

/**
 * アカウントのユーザー取得リクエスト。
 *
 * @param {string} access_token - Auth0 アクセストークン
 * @param {number|string} accountId - アカウント UUID
 */
export function managerSelectAccountUsers(access_token, accountId) {
    return {
        type: types.MANAGER_REQUEST_ACCOUNT_USERS,
        payload: {
            access_token: access_token,
            accountId: accountId
        }
    }
}

/**
 * アカウントのユーザー取得リクエスト（ロード中）
 */
export function managerSelectAccountUserLoad() {
    return {
        type: types.MANAGER_REQUEST_ACCOUNT_USERS_LOADING
    }
}

/**
 * アカウントのユーザー一覧取得リクエスト（成功）
 * @param {Object} accountUsers - アカウントのユーザー一覧
 */
export function managerSelectAccountUserSuccess(accountUsers) {
    return {
        type: types.MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS,
        payload: {
            accountUsers: accountUsers
        }
    }
}

/**
 * アカウントのユーザー一覧取得リクエスト（失敗）
 * @param {Object} e - エラー内容
 */
export function managerSelectAccountUserFailure(e) {
    return {
        type: types.MANAGER_REQUEST_ACCOUNT_USERS_FAILURE,
        payload: {
            error: e
        }
    }
}


const actions = {
    managerLoadAccountList,
    managerLoadingAccountList,
    managerLoadedAccountList,
    managerFailureAccountList,

    managerViewRegisterToggle,
    managerViewRegisterToggleInitSFAccountLoad,
    managerSearchSalesForceAccount,
    managerSelectedSalesForceAccountClear,
    managerSelectedSalesForceAccountSuccess,
    managerSelectedSalesForceAccountFailure,
    managerLoadingSalesForceAccount,
    managerSelectedSalesForceAccount_LoadUsers,
    managerSelectedSalesForceAccount,
    managerSearchSalesForceUser,
    managerSelectedSalesForceUserClear,
    managerSelectedSalesForceUserSuccess,
    managerSelectedSalesForceUserFailure,
    managerLoadingSalesForceUser,
    managerSelectedSalesForceUser,

    managerRegisterNewAccount,
    managerRegisterNewAccountLoading,
    managerRegisterNewAccountSuccess,
    managerRegisterNewAccountFailure,

    managerViewEditToggle,
    managerEditAccount,
    managerEditAccountSuccess,
    managerEditAccountFailure,
    managerAccountEditLoad,

    managerRequestSearchAccounts,
    managerLoadingSearchAccounts,
    managerSuccessSearchAccounts,
    managerFailureSearchAccounts,

    managerViewDeleteToggle,
    managerDeleteAccount,
    managerDeleteAccountLoading,
    managerDeleteAccountSuccess,
    managerDeleteAccountFailure,

    managerChangePageAccountList,

    managerSelectAccountUsers,
    managerSelectAccountUserLoad,
    managerSelectAccountUserSuccess,
    managerSelectAccountUserFailure
};
export default actions
