import {addressListOperations} from "../../../../state/ducks/user/addresses";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AddressView from "../../../components/user/addresses/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,

        loading: state.address.loading,
        addresses: state.address.addressList,
        isRegisterView: state.address.isRegisterView,
        addressRegistrationLoad: state.address.registerLoading,
        registerError: state.address.registerError,
        error: state.address.error,
        addressList_CurrentPage: state.address.addressList_CurrentPage,
        activeProfile: state.address.activeProfile,
        deleteView: state.address.deleteView,
        deleteLoading: state.address.deleteLoading,
        deleteError: state.address.deleteError,
        deleteErrorModalView: state.address.deleteErrorModalView
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    toggleRegisterView: addressListOperations.toggleRegister,
    registerAddress: addressListOperations.registerAddress,
    changePage: addressListOperations.addressChangePage,
    updateAddress: addressListOperations.updateAddress,
    viewAddressDeleteToggle: addressListOperations.viewAddressDeleteToggle,
    deleteAddresses: addressListOperations.deleteAddresses,
    confirmDeleteAddressDoNotDelete: addressListOperations.confirmDeleteAddressDoNotDelete
}

/**
 * Redux
 * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const AddressAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(AddressView)

export default AddressAccountContainer;
