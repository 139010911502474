import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているrootグループに束縛されています。
 * //////////////////////////////////////////
 */

export const rootState = {
    reLoad: true,
    isError: false,
    errorDetail: {},
    debug_response: "",

    accessTokenFailureDialog: false,
    accessTokenFailureError: {},

    managerProfile: {}
};

export default function rootReducer(state = rootState, action) {
    switch (action.type) {
        case types.ERROR_OCCURRED_EVENT:
            return {
                ...state,
                isError: state.isError = true,
                reLoad: state.reLoad = false,
                errorDetail: state.errorDetail = action.payload.error
            }
        case types.ERROR_CONFIRM_EVENT:
            return {
                ...state,
                isError: state.isError = false,
                reLoad: state.reLoad = true,
                errorDetail: state.errorDetail = {}
            }
        case types.GET_MANAGER_PROFILE_SUCCESS:
            return {
                ...state,
                managerProfile: state.managerProfile = action.payload.user_profile
            }
        // FIXME: ERROR RETURN FOR GET_MANAGER_PROFILE_FAILURE EVENT
        case types.GET_MANAGER_PROFILE_FAILURE:
            return state;
        case types.ACCESS_TOKEN_FAILURE:
            return {
                ...state,
                accessTokenFailureDialog: state.accessTokenFailureDialog = true,
                accessTokenFailureError: state.accessTokenFailureError = action.payload.error
            }
        case types.DEBUG_RESPONSE:
            return {
                ...state,
                debug_response: state.debug_response = action.debug_response
            }
        case types.DEBUG_RESPONSE_CLEAR:
            return {
                ...state,
                debug_response: state.debug_response = ""
            }
        default:
            return state;
    }
}
