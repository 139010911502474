/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないserviceContractsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const GET_SERVICE_CONTRACTS_API_PATH = "/accounts/{UID}/service_contracts/";
export const GET_SERVICE_CONTRACT_API_PATH = "/accounts/{UID}/service_contracts/{SUID}"
export const GET_SERVICE_CONTRACT_CONTACT_API_PATH = "/accounts/{UID}/service_contracts/{SUID}/contacts/{CUID}"
export const GET_SERVICE_CONTRACT_CONTACT_ADDRESS_API_PATH = "/accounts/{UID}/service_contracts/{SUID}/contacts/{CUID}/addresses"
