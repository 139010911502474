/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /managerUsers 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使って妥当性確認を設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

export const managerUserStoreValidation = {
    last_name: {
        // このフィールドに必要な文字数は80文字以下です。
        length: {maximum: 80},
        // これは、フォームがこの入力を必要とすることを意味します。
        presence: true
    },
    first_name: {
        length: {maximum: 40},
        presence: true,
    },
    email: {
        length: {maximum: 80},
        presence: true,
        // これは、フォームの入力に有効なメールアドレスが必要だったことを意味します。
        email: true
    }
}
