import React from 'react';
import {Button, Card, CardContent, CardHeader, Grid, makeStyles, Typography} from '@material-ui/core';
import {useAuth0} from "@auth0/auth0-react";
import ContactEditModal from "../modal/ContactEditModal";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {empty} from "../../../../../state/utils/Common";

/**
 * コンポーネントスタイル
 * @type {(props?: any) => ClassNameMap<"title">}
 */
const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "900"
    },
    contactInfo: {
        paddingLeft: "20px !important",
        paddingRight: "20px !important",
    }
}));

/**
 * 担当者情報コンポーネント
 *
 * @param {*} toggleEditContact - 担当者情報変更モーダル表示・非表示状態
 * @param {Object} activeServiceContract - 選択した利用契約
 * @param {Object} activeServiceContractContact - 利用契約の担当者情報（請求担当者｜アカウント担当者）
 * @param {boolean} contactEditModal - 担当者の変更モーダルの表示・非表示状態
 * @param {Object} activeProfile - ローグインしたユーザー情報
 * @param {Object} contactData - 担当者情報
 * @param {string} contactKey - 利用契約の担当者タイプ（請求担当者｜アカウント担当者）
 * @param {*} loadServiceContractContact - 担当者変更モーダルのロード中状態
 * @param {boolean} contactEditInitLoad - 最初コンポーネントロードの状態
 * @param {*} accountEditAction - 更新アクション
 * @param {boolean} accountEditLoading - 更新のロード中状態
 * @param {Object} accountEditError - 更新エラー
 * @param {string} title - 担当者のタイトル
 * @param {string} editTitle - 担当者の情報変更モーダルボックスのタイトル
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const Contact = (
    {
        toggleEditContact,
        activeServiceContract,
        activeServiceContractContact,

        contactEditModal,
        activeProfile,
        contactData,
        contactKey,

        loadServiceContractContact,
        contactEditInitLoad,
        accountEditAction,
        accountEditLoading,
        accountEditError,
        title,
        editTitle,
        t
    }) => {
    // グローバルコンポーネントスタイル
    const classes = useStyles();
    const cardStyle = globalTheme.defaultCards();
    const defaultTheme = globalTheme._default();
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Card elevation={0} className={defaultTheme.border}>
            {/* 編集クリックで編集モーダルを呼び出す */}
            <CardHeader title={title} className={cardStyle.headers} action={
                <Button variant="contained" color="secondary" className={defaultTheme.border} style={{marginTop: 10}} onClick={() => {
                    (async () => {
                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                            () => {
                                return getAccessTokenSilently();
                            },
                        );
                        loadServiceContractContact(() => (toggleEditContact), contactData.uuid, activeServiceContract.uuid, access_token, activeProfile?.account?.uuid)
                    })()
                }}>
                    {t('actions.edit')}
                </Button>
            }/>
            <CardContent>
                {/* 担当者情報 */}
                <Grid container spacing={2}>
                    <Grid item xs={3} className={classes.contactInfo}><Typography variant="body1"
                                                  className={classes.title}>{t('service_contracts.detail.name')}</Typography></Grid>
                    <Grid item xs={9}><Typography
                        variant="body1">{contactData.last_name} {contactData.first_name}</Typography></Grid>
                    <Grid item xs={3} className={classes.contactInfo}><Typography variant="body1"
                                                  className={classes.title}>{t('service_contracts.detail.work_phone')}</Typography></Grid>
                    <Grid item xs={9}><Typography
                        variant="body1">{!empty(contactData.work_phone) ? contactData.work_phone : t('general.not_yet_registered')}</Typography></Grid>
                    <Grid item xs={3} className={classes.contactInfo}><Typography variant="body1"
                                                  className={classes.title}>{t('service_contracts.detail.mobile_phone')}</Typography></Grid>
                    <Grid item xs={9}><Typography
                        variant="body1">{!empty(contactData.mobile_phone) ? contactData.mobile_phone : t('general.not_yet_registered')}</Typography></Grid>
                    <Grid item xs={3} className={classes.contactInfo}><Typography variant="body1"
                                                  className={classes.title}>{t('service_contracts.detail.email')}</Typography></Grid>
                    <Grid item xs={9}><Typography variant="body1">{contactData.email}</Typography></Grid>
                    <Grid item xs={3} className={classes.contactInfo}><Typography variant="body1"
                                                  className={classes.title}>{t('service_contracts.detail.department')}</Typography></Grid>
                    <Grid item xs={9}><Typography
                        variant="body1">{!empty(contactData.department) ? contactData.department : t('general.not_yet_registered')}</Typography></Grid>
                </Grid>
            </CardContent>
            {/* 担当者情報の変更ダイアログボックス */}
            <ContactEditModal
                isOpen={contactEditModal}
                title={editTitle}
                setClose={toggleEditContact}
                account={activeServiceContract}
                loadServiceContractContact={loadServiceContractContact}
                activeServiceContractContact={activeServiceContractContact}
                contactEditInitLoad={contactEditInitLoad}
                ContactData={contactData}
                contactKey={contactKey}
                accountEdit={accountEditAction}
                registerLoading={accountEditLoading}
                registerError={accountEditError}
                profile={activeProfile}
                t={t}
            />
        </Card>
    );
}

export default Contact;
