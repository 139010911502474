import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";

export const AccountUserListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.users[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={6}>
                <Typography variant={"body1"} className={commonTheme.breakWrap}>
                    {props.data.users[props.index].last_name} {props.data.users[props.index].first_name}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.users[props.index].email}
                </Typography>
            </Grid>
        </Grid>
    )
}
