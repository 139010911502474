/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /service_contracts 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

/**
 * 連絡先妥当性確認
 */
export const contactValidation = {
    last_name: {
        presence: true,
        length: {maximum: 80}
    },
    first_name: {
        presence: true,
        length: {maximum: 40}
    },
    work_phone: {
        eitherFieldRequired: {compareField: "mobile_phone"},
        length: {maximum: 40},
        presence: false,
        phone_validation: true,
    },
    mobile_phone: {
        length: {maximum: 40},
        presence: false,
        phone_validation: true,
    },
    email: {
        presence: true,
        length: {maximum: 80},
        email: true
    },
    department: {
        presence: false,
        length: {maximum: 255}
    }
}

/**
 * 連絡先の住所妥当性確認
 */
export const addressValidation = {
    address: {
        presence: true
    }
}
