/**
 * ////////////////////////////////////////
 * MATERIAL UI THEME OVERRIDES - PROPS
 * ------------------------------------
 *
 * MATERIAL UIテーマのオーバーライド - プロップス
 * ////////////////////////////////////////
 */
const props = {
    MuiButton: { // コンポーネント名 ⚛️ / スタイルシート
        disableRipple: true
    }
}
export default props;
