import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {serviceContractOperations} from "../../../../state/ducks/user/service_contracts";
import {useAuth0} from "@auth0/auth0-react";
import ContactView from "../../../components/user/serviceContracts/Contact";
import {empty} from "../../../../state/utils/Common";
import {useParams} from "react-router";
import Auth0Utils from "../../../../state/utils/Auth0Utils";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        activeServiceContract: state.serviceContract.activeServiceContract,
        contactBilledToEditModal: state.serviceContract.contactBilledToEditModal,
        contactSoldToEditModal: state.serviceContract.contactSoldToEditModal,
        activeServiceContractError: state.serviceContract.activeServiceContractError,
        contactLoading: state.serviceContract.contactLoading,
        addressBilledToEditModal: state.serviceContract.addressBilledToEditModal,
        addressSoldToEditModal: state.serviceContract.addressSoldToEditModal
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    toggleSoldToModal: serviceContractOperations.toggleSoldToModal,
    toggleBilledToModal: serviceContractOperations.toggleBilledToModal,
    requestSingleServiceContract: serviceContractOperations.requestSingleServiceContract,
    loadServiceContractContactAddressSoldToToggle: serviceContractOperations.loadServiceContractContactAddressSoldToToggle,
    loadServiceContractContactAddressBilledToToggle: serviceContractOperations.loadServiceContractContactAddressBilledToToggle,
    activeServiceContractError: serviceContractOperations.activeServiceContractError
}

/*
利用契約 -> 連絡先コンテイナ
 */
const _contentsContainer = (
    {
        activeServiceContract,
        toggleSoldToModal,
        toggleBilledToModal,
        contactBilledToEditModal,
        contactSoldToEditModal,
        addressBilledToEditModal,
        addressSoldToEditModal,
        loadServiceContractContactAddressSoldToToggle,
        loadServiceContractContactAddressBilledToToggle,
        requestSingleServiceContract,
        activeServiceContractError,
        contactLoading,
        managerProfile,
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {id} = useParams();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestSingleServiceContract(access_token, managerProfile?.account?.uuid, id)
            })()
        }
    }, [getAccessTokenSilently, id, managerProfile, requestSingleServiceContract])
    return (
        <ContactView
            managerProfile={managerProfile}

            activeServiceContract={activeServiceContract}
            toggleBilledToModal={toggleBilledToModal}
            addressBilledToEditModal={addressBilledToEditModal}
            addressBilledToEditModalEvent={loadServiceContractContactAddressBilledToToggle}
            toggleSoldToModal={toggleSoldToModal}
            addressSoldToEditModal={addressSoldToEditModal}
            activeServiceContractError={activeServiceContractError}
            addressSoldToEditModalEvent={loadServiceContractContactAddressSoldToToggle}
            t={t}
            contactLoading={contactLoading}
            contactSoldToEditModal={contactSoldToEditModal}
            contactBilledToEditModal={contactBilledToEditModal}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * _contentsContainer コンポーネントに追加する。
 */
const ContentsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_contentsContainer)

export default ContentsContainer;
