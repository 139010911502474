import {call, put} from 'redux-saga/effects';
import {
    managerDeleteUserFailure, managerDeleteUserLoading,
    managerDeleteUserSuccess,
    managerFailureSearchUsers,
    managerFailureUserList,
    managerLoadedUserList,
    managerLoadingSearchUsers,
    managerLoadingUserList,
    managerLoadUserList,
    managerRegisterNewUserFailure,
    managerRegisterNewUserSuccess,
    managerSuccessSearchUsers,
    managerUserRegistrationLoad
} from "./actions";
import {
    managerAddUsers,
    managerAllUsers,
    managerDeleteUser as userDeleteAPI,
    managerUpdateUser as userUpdateAPI
} from "./api";
import {error_raise} from "../../root/actions";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * ユーザー一覧取得リクエスト
 * @param data - data containing access Token
 */
export function* managerSelectUserList(data) {
    yield put(managerLoadingUserList())
    try {
        const users = yield call(managerAllUsers, data.payload.access_token, null);
        yield put(managerLoadedUserList(users));
    } catch (e) {
        yield put(managerFailureUserList(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 新規ユーザーリクエスト
 *
 * @param userData - new user information and access token
 */
export function* managerAddUserList(userData) {
    yield put(managerUserRegistrationLoad())
    try {
        yield call(managerAddUsers, userData.payload.user, userData.payload.access_token)
        yield put(managerRegisterNewUserSuccess());
        yield put(managerLoadUserList(userData.payload.access_token))
    } catch (e) {
        yield put(managerRegisterNewUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 検索ユーザーリクエスト
 * @param data - キーワードとauth0アクセストークン
 */
export function* managerFilterUserList(data) {
    const userSearchTerm = {search: data.payload.searchUsers};
    yield put(managerLoadingSearchUsers())
    try {
        const searchResults = yield call(managerAllUsers, data.payload.access_token, userSearchTerm)
        yield put(managerSuccessSearchUsers(searchResults.data.users))
    } catch (e) {
        yield put(managerFailureSearchUsers(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ユーザー削除リクエスト
 *
 * @param accountData - ユーザーIDとauth0アクセストークン
 */
export function* managerDeleteUser(accountData) {
    yield put(managerDeleteUserLoading())
    try {
        yield call(userDeleteAPI, accountData.payload.user, accountData.payload.access_token)
        yield put(managerDeleteUserSuccess())
        yield put(managerLoadUserList(accountData.payload.access_token))
    } catch (e) {
        yield put(managerDeleteUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ユーザー編集リクエスト
 *
 * @param userData - アクセストークン, ユーザーID and 更新ユーザー情報
 * */
export function* managerUpdateUser(userData) {
    yield put(managerUserRegistrationLoad())
    try {
        yield call(userUpdateAPI, userData.payload.user_id, userData.payload.user, userData.payload.access_token)
        yield put(managerRegisterNewUserSuccess());
        yield put(managerLoadUserList(userData.payload.access_token))
    } catch (e) {
        yield put(managerRegisterNewUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

