/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（addresses）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const addressWatcherSagas = Object.values(watchersSagas);
export {default as addressListSelectors} from "./selectors";
export {default as addressListOperations} from "./operations";
export {default as addressListTypes} from "./types";
export {default as addressForms} from "./forms"

export {addressListState, default as addressReducer} from './reducers';
