import {Box, Grid, Typography} from "@material-ui/core";
import ExpandDropDown from "../../../../atoms/ExpandDropDown";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";

export const AddressesListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.addresses[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.t('address.postal_code_sign')}{props.data.addresses[props.index].postal_code}
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.addresses[props.index].state} {props.data.addresses[props.index].city} {props.data.addresses[props.index].address}
                </Typography>
            </Grid>
            <Grid item xs={1} style={{marginTop: -5}}>
                <Box
                    justifyContent="flex-end"
                    display="flex"
                >
                    {/**
                     * 住所削除, 住所編集をドロップダウンアトムコンポーネントに追加します。
                     **/}
                    <ExpandDropDown
                        menuItems={
                            [
                                {
                                    name: props.data.t('actions.edit'),
                                    event: () => props.data.handleEditClick(props.data.addresses[props.index])
                                },
                                {
                                    name: props.data.t('actions.delete'),
                                    event: () => props.data.handleDeleteClick(props.data.addresses[props.index])
                                }
                            ]
                        }/>
                </Box>
            </Grid>
        </Grid>
    )
}
