import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトの管理者アカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているmanagerAccountsグループに束縛されています。
 * //////////////////////////////////////////
 */

export const managerAccountListState = {
    managerAccountIsRegisterView: false,
    managerAccountIsEditView: false,

    managerAccountRegisterError: {},
    managerAccountRegisterLoading: false,

    currentPage: 1,
    totalPage: 0,

    managerDeleteView: false,
    managerLoading: true,
    managerIsError: false,

    managerSearchLoad: false,
    managerSearchAccountResult: {},

    managerError_code: "",
    managerError: {},
    managerAccountList: {},

    loadingSFUser: false,
    loadingSFAccount: false,
    sfAccountError: {},
    sfUserError: {},
    managerActiveSalesForceAccount: {},
    managerActiveSalesForceAccountList: {},
    managerActiveSalesForceUser: {},
    managerActiveSalesForceUserList: {},

    managerAccountUsersLoading: true,
    managerAccountActiveUsers: {},
    managerAccountUsersError: {},

    managerDeleteError: {},
    managerDeleteLoading: false,

    managerActiveId: null
};

export default function accountList(state = managerAccountListState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // アカウント取得
        // -----------------------------------------------
        case types.MANAGER_REQUEST_ACCOUNTS_LOADING:
            return {
                ...state,
                managerLoading: state.managerLoading = true,
                managerIsError: state.managerIsError = false
            }
        case types.MANAGER_REQUEST_ACCOUNTS_SUCCESS:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerAccountList: state.managerAccountList = action.payload,
                managerIsError: state.managerIsError = false,
                managerError: state.managerError = {},
                managerError_code: state.managerError_code = ""
            }
        case types.MANAGER_REQUEST_ACCOUNTS_FAILURE:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerError: state.managerError = action.payload.managerError,
                managerAccountList: state.managerAccountList = {},
                managerIsError: state.managerIsError = true,
            }
        case types.MANAGER_CHANGE_ACCOUNT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage = action.payload.pageNo
            }
        // -----------------------------------------------
        // アカウント登録
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_TOGGLE_REGISTER:
            return {
                ...state,
                managerActiveSalesForceAccount: state.managerActiveSalesForceAccount = {},
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {},
                sfAccountError: state.sfAccountError = {},
                managerAccountRegisterError: state.managerAccountRegisterError = {},
                sfUserError: state.sfUserError = {},
                managerAccountIsRegisterView: !state.managerAccountIsRegisterView
            }
        case types.MANAGER_TOGGLE_DELETE:
            return {
                ...state,
                managerDeleteView: !state.managerDeleteView,
                managerDeleteError: state.managerDeleteError = {},
                managerActiveId: state.managerActiveId = action.payload.accountId
            }

        case types.MANAGER_ADD_ACCOUNT_LOADING:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = true,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
            }

        case types.MANAGER_ADD_ACCOUNT_SUCCESS:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
                managerAccountIsRegisterView: state.managerAccountIsRegisterView = false,
            }

        case types.MANAGER_ADD_ACCOUNT_FAILURE:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = action.payload.managerError,
            }
        // -----------------------------------------------
        // アカウント検索
        // -----------------------------------------------
        case types.MANAGER_SEARCH_ACCOUNT_LOADING:
            return {
                ...state,
                managerSearchLoad: state.managerSearchLoad = true,
                managerIsError: state.managerIsError = false,
            }

        case types.MANAGER_SEARCH_ACCOUNT_SUCCESS:
            return {
                ...state,
                managerSearchLoad: state.managerSearchLoad = false,
                managerIsError: state.managerIsError = false,
                managerSearchAccountResult: state.managerSearchAccountResult = action.payload.accountResult
            }

        case types.MANAGER_SEARCH_ACCOUNT_FAILURE:
            return {
                ...state,
                managerSearchLoad: state.managerSearchLoad = false,
                managerIsError: state.managerIsError = true,
                managerSearchAccountResult: state.managerSearchAccountResult = {},
                managerError: state.managerError = action.payload.managerError
            }
        // -----------------------------------------------
        // アカウント削除
        // -----------------------------------------------
        case types.MANAGER_DELETE_ACCOUNT_LOADING:
            return {
                ...state,
                managerDeleteLoading: true,
                managerDeleteError: {}
            }
        case types.MANAGER_DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                managerDeleteLoading: false,
                managerDeleteError: state.managerDeleteError = {},
                managerDeleteView: state.managerDeleteView = false
            }

        case types.MANAGER_DELETE_ACCOUNT_FAILURE:
            return {
                ...state,
                managerDeleteLoading: false,
                managerDeleteError: state.managerError = action.payload.error,
            }
        // -----------------------------------------------
        // アカウント変更
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_TOGGLE_EDIT:
            return {
                ...state,
                managerAccountIsEditView: !state.managerAccountIsEditView,
                managerActiveId: action.payload.account,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
            }
        case types.MANAGER_EDIT_ACCOUNT_LOADING:
            return {
                ...state,
                managerAccountRegisterLoading: !state.managerAccountRegisterLoading,
            }
        case types.MANAGER_EDIT_ACCOUNT_SUCCESS:
            return {
                ...state,
                managerAccountIsEditView: state.managerAccountIsEditView = false,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
            }
        case types.MANAGER_EDIT_ACCOUNT_FAILURE:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = action.payload,
            }
        // -----------------------------------------------
        // SFアカウント編集
        // -----------------------------------------------
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_LOADING:

            return {
                ...state,
                loadingSFAccount: state.loadingSFAccount = true,
                sfAccountError: state.sfAccountError = {},
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_CLEAR:
            return {
                ...state,
                managerActiveSalesForceAccount: state.managerActiveSalesForceAccount = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {},
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
            }
        case types.MANAGER_SEARCH_SALESFORCE_USER_CLEAR:
            return {
                ...state,
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loadingSFAccount: state.loadingSFAccount = false,
                sfAccountError: state.sfAccountError = {},
                managerActiveSalesForceAccountList: state.managerActiveSalesForceAccountList = action.payload.salesForceAccounts
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingSFAccount: state.loadingSFAccount = false,
                sfAccountError: state.sfAccountError = action.payload
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_SELECT:
            return {
                ...state,
                managerActiveSalesForceAccount: state.managerActiveSalesForceAccount = action.payload.salesForceAccount,

            }
        // -----------------------------------------------
        // SFユーザー検索
        // -----------------------------------------------
        case types.MANAGER_SEARCH_SALESFORCE_USER_LOADING:
            return {
                ...state,
                loadingSFUser: state.loadingSFUser = true,
                sfUserError: state.sfUserError = {},
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {}
            }

        case types.MANAGER_SEARCH_SALESFORCE_USER_SUCCESS:
            return {
                ...state,
                loadingSFUser: state.loadingSFUser = false,
                sfUserError: state.sfUserError = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = action.payload.salesForceUsers
            }

        case types.MANAGER_SEARCH_SALESFORCE_USER_FAILURE:
            return {
                ...state,
                loadingSFUser: state.loadingSFUser = false,
                sfUserError: state.sfUserError = action.payload,
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {}
            }
        case types.MANAGER_SEARCH_SALESFORCE_USER_SELECT:
            return {
                ...state,
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = action.payload.salesForceUser,
            }
        case types.MANAGER_REQUEST_ACCOUNT_USERS_LOADING:
            return {
                ...state,
                managerAccountUsersLoading: state.managerAccountUsersLoading = true,
                managerAccountUsersError: state.managerAccountUsersError = {},
                managerAccountActiveUsers: state.managerAccountActiveUsers = {}
            }
        case types.MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS:
            return {
                ...state,
                managerAccountUsersLoading: state.managerAccountUsersLoading = false,
                managerAccountUsersError: state.managerAccountUsersError = {},
                managerAccountActiveUsers: state.managerAccountActiveUsers = action.payload.accountUsers
            }
        case types.MANAGER_REQUEST_ACCOUNT_USERS_FAILURE:
            return {
                ...state,
                managerAccountUsersLoading: state.managerAccountUsersLoading = false,
                managerAccountUsersError: state.managerAccountUsersError = action.payload.error,
            }
        default:
            return state;
    }
}
