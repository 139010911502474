/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないrootに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const CALL_PROFILE = "/profile/";
export const ERROR_CATCH_API = "/error";
