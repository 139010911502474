import React from "react";
import ManagerLoginContainer from "../../containers/manager/ManagerLoginContainer";

/**
 * 管理者側のサインアウトページ
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerSignOutPage = () => {
    return (
        <div>
            <ManagerLoginContainer/>
        </div>
    )
}

export default ManagerSignOutPage;
