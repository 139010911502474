import storeProvider from '../../StoreProvider'
import {accessTokenFailure} from "../ducks/root/actions";
import {stackTracer} from "./Common"
/**
 * /////////////////////////////////////////////
 * AUTH0 ユーティリティー
 * -------------------------
 *
 * Auth0のユーティリティー関数
 * /////////////////////////////////////////////
 */

/**
 *
 * @param {*} accessTokenFunction
 * @param {boolean} force - セッションクッキーを強制的にチェックする
 * @returns {Promise<*>}
 */
const checkAndUpdateAccessToken = async (accessTokenFunction, force= false) => {
    if(
        force === false && (
        storeProvider.getStore().getState().root.isError === true ||
        storeProvider.getStore().getState().root.accessTokenFailureDialog === true)
    ) {
        return ""
    }
    const stack = stackTracer();
    return accessTokenFunction().catch((t) => {
        storeProvider.getStore().dispatch(accessTokenFailure({
            translation_error_key: "actions.access_token_message",
            stack: stack,
            error: JSON.parse(JSON.stringify(t, Object.getOwnPropertyNames(t))),
        }))
    });
}

const Auth0Utils = {
    checkAndUpdateAccessToken
}
export default Auth0Utils;
