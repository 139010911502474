import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ManagerAccountCreate from "../../../../components/manager/accounts/modal/Create";
import {managerAccountListOperations} from "../../../../../state/ducks/manager/managerAccounts";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        loadingSFUser: state.managerAccount.loadingSFUser,
        loadingSFAccount: state.managerAccount.loadingSFAccount,
        sfAccountError: state.managerAccount.sfAccountError,
        sfUserError: state.managerAccount.sfUserError,
        managerActiveSalesForceAccount: state.managerAccount.managerActiveSalesForceAccount,
        managerActiveSalesForceAccountList: state.managerAccount.managerActiveSalesForceAccountList,
        managerActiveSalesForceUser: state.managerAccount.managerActiveSalesForceUser,
        managerActiveSalesForceUserList: state.managerAccount.managerActiveSalesForceUserList,

        managerAccountIsRegisterView: state.managerAccount.managerAccountIsRegisterView,
        managerAccountRegisterLoading: state.managerAccount.managerAccountRegisterLoading,
        managerAccountRegisterError: state.managerAccount.managerAccountRegisterError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    managerSearchSalesForceAccount: managerAccountListOperations.managerSearchSalesForceAccount,
    managerSelectedSalesForceAccount: managerAccountListOperations.managerSelectedSalesForceAccount,
    managerSearchSalesForceUser: managerAccountListOperations.managerSearchSalesForceUser,
    managerSelectedSalesForceUser: managerAccountListOperations.managerSelectedSalesForceUser,

    managerViewRegisterToggle: managerAccountListOperations.managerViewRegisterToggle,
    managerRegisterNewAccount: managerAccountListOperations.managerRegisterNewAccount,
    managerSelectedSalesForceAccount_LoadUsers: managerAccountListOperations.managerSelectedSalesForceAccount_LoadUsers,
    managerSelectedSalesForceAccountClear: managerAccountListOperations.managerSelectedSalesForceAccountClear,
    managerSelectedSalesForceUserClear: managerAccountListOperations.managerSelectedSalesForceUserClear
}

/**
 * * Redux
 * * i18next Translations
 * を
 * ManagerAccountCreate コンポーネントにマージする。
 */

const ManagerCreateAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(ManagerAccountCreate)

export default ManagerCreateAccountContainer;
