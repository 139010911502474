/**
 * ログインセッション自動ログアウト管理
 */
class SessionTimeoutUtil {
    // イベントハンドル
    eventHandler = null
    // 有効期限チェックのインターバル
    interval = null
    // ユーザー行動チェックのインターバル
    updateSessionTimeoutTrack = null

    /**
     * コンストラクタ
     * @param auth0logout
     */
    constructor(auth0logout) {
        this.eventHandler = this.updateSessionTimeTimeout.bind(this)
        this.auth0Logout = auth0logout;
    }

    /**
     * セッション管理自動開始
     * @returns {SessionTimeoutUtil}
     */
    startSessionCheck() {
        // 最初に新しい有効期限設定をlocalStorageに保存する
        this._updateSessionTime()
        // x秒毎に有効期限を確認する
        this.interval = setInterval(() => {
            const sessionTime = this._parseSessionData(this._getSessionData()).sessionExpiryDate
            // 有効期限が超えた場合は
            if(sessionTime < Date.now() && typeof this.auth0Logout === 'function') {
                this.disableUserTracking().auth0Logout()
            }
        }, parseInt(process.env.REACT_APP_USER_SESSION_CHECK_TIMEOUT_INTERVAL_SECONDS) * 1000)
        return this;
    }

    /**
     * 有効期限のタイマーを設定する.
     */
    updateSessionTimeTimeout() {
        // 以前の行動タイマーがあった場合、以前のタイマーを削除する
        if(this.updateSessionTimeoutTrack) {
            clearTimeout(this.updateSessionTimeoutTrack)
        }
        // タイマーが超えた場合、有効期限を更新する.
        this.updateSessionTimeoutTrack = setTimeout(() => {
            this._updateSessionTime()
        }, parseInt(process.env.REACT_APP_USER_SESSION_CHECK_USER_INPUT_INTERVAL_SECONDS) * 1000)
    }

    /**
     * ユーザー行動トラッキングを有効にする
     * @returns {SessionTimeoutUtil}
     */
    enableUserTracking() {
        window.addEventListener('mousemove', this.eventHandler)
        window.addEventListener('scroll', this.eventHandler)
        window.addEventListener('keydown', this.eventHandler)
        window.addEventListener('click', this.eventHandler)
        return this;
    }

    /***
     * ユーザー行動トラッキングを無効にする
     * ※ 有効期限確認タイマーも無効にする。
     * @returns {SessionTimeoutUtil}
     */
    disableUserTracking() {
        // 有効期限確認タイマーも無効にする。
        clearInterval(this.interval)
        // ユーザー行動トラッキングデータを削除する
        localStorage.removeItem('sessionData')
        // ユーザー行動トラッキングを無効にする
        window.removeEventListener('mousemove', this.eventHandler)
        window.removeEventListener('scroll', this.eventHandler)
        window.removeEventListener('keydown', this.eventHandler)
        window.removeEventListener('click', this.eventHandler)
        return this
    }

    /**
     * セッションの有効期限データの取得
     * @returns {string} - セッションの有効期限データ (JSON系)
     * @private
     */
    _getSessionData() {
        return localStorage.getItem('sessionData')
    }

    /**
     * セッションの有効期限データの更新
     * @returns {SessionTimeoutUtil}
     * @private
     */
    _updateSessionTime() {
        localStorage.setItem('sessionData', this._buildSessionDataObject())
        return this;
    }

    /**
     * セッションの有効期限データをパースする
     * @param jsonObject
     * @returns {any}
     * @private
     */
    _parseSessionData(jsonObject) {
        return JSON.parse(jsonObject);
    }

    /**
     * 有効期限オブジェクトを作成する。
     * @returns {string}
     * @private
     */
    _buildSessionDataObject() {
        return JSON.stringify({
            'sessionExpiryDate': Date.now() + parseInt(process.env.REACT_APP_USER_SESSION_TIMEOUT_SECONDS) * 1000
        })
    }
}
export default SessionTimeoutUtil;







