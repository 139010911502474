import React from 'react';
import {CircularProgress, Grid} from "@material-ui/core";

/**
 * ローディング・コンポーネント
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Loading = () => {
    return (
        <Grid
            container
            spacing={0}
            align="center"
            justify="center"
        >
            <Grid item>
                <CircularProgress/>
            </Grid>
        </Grid>
    );
};

export default Loading;
