import React from 'react'
import {MenuItem} from "@material-ui/core";

/**
 * ドロップダウンメニューアイテム
 * @param props - ドロップダウンアイテムをクリックしたときのイベント
 * @param ref - アクションの可視化名
 * @param {function} props.handleClose -
 */
const ExpandDropDownItems = React.forwardRef((props, ref) => {
    return (
        <MenuItem ref={ref} onClick={() => {
            props.handleClose();
            props.onClickEvent();
        }}>
            {props.itemName}
        </MenuItem>
    );
})
export default ExpandDropDownItems;


