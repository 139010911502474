import React from 'react';
import ErrorLayoutContainer from "../../containers/root/ErrorLayoutContainer";

/**
 * エラーレイアウト (エラーレイアウト（空テンプレートを表示)
 *
 * @param {string} title - タイトル
 * @param {string} message - エラー内容
 * @param {*} t - 翻訳ファイル
 * @param error_code
 * @param errorObject
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorLayout = ({title, message, error_code, errorObject, sessionUtil}) => {
    return (
        <ErrorLayoutContainer
            title={title}
            message={message}
            errorObject={errorObject}
            error_code={error_code}
            sessionUtil={sessionUtil}
        />
    )
};

export default ErrorLayout;
